import React, { useState, useEffect } from "react";
import { SyncOutlined } from "@ant-design/icons";
import style from "./QtyModal.module.css";
import axiosClient from "../../../service/axios";

const QtyModal = ({ setIsOpen, id, title }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getQty = async () => {
    setLoading(true);
    try{
        const res = await axiosClient.get(`/lite/v2/api/v3/view/order/details/${title}/${id}`);
        setLoading(false);
        setShowSpinner(false);
        setData(res.data.product)
    }catch(error){
        console.log(error)
    }
  }

  useEffect(() => {
    getQty();
  }, [])


 
  return (
    <>
      <div className={style.darkBG} onClick={() => setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div>
            <table className={style.table} style={{ borderRadius: "10px" }}>
              <thead>
                <tr className={style.headerrow}>
                  <th className={style.headeritem}>Name</th>
                  <th className={style.headeritem}>Quantity</th>
                  <th className={style.headeritem}>Cost</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="2">
                      <div className={style.loadingCell}>
                        <SyncOutlined spin />
                      </div>
                    </td>
                  </tr>
                ) : (
                  data?.map((items, index) => (
                    <tr key={index} className={style.container}>
                      <td className={style.card}>
                        <div className={style.cardText}>{items.name}</div>
                      </td>
                      <td className={style.card}>
                        <div className={style.cardText}>{items.qty}</div>
                      </td>
                      <td className={style.card}>
                        <div className={style.cardText}>{items.cost}</div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default QtyModal;