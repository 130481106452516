import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../Form/SelectField";
import style from "./Split.module.scss";
import { TbArrowsLeftRight } from "react-icons/tb";

import { useDispatch, useSelector } from "react-redux";
import {
  addNewOrder,
  placeOrder,
} from "../../../redux/new-order/actions/new-orders.actions";
import { SyncOutlined } from "@ant-design/icons";
// import { SuccessModal } from "../../Modal/SuccessModal";
// import MessageInstruction from "../../modalInstruction/MessageInstruction";
import { useNavigate } from "react-router-dom";
import { failedOrder, orderCreateSuccessToast } from "../../NotificationToast";

const ProcessCardSingle = (props) => {
  const [riderDropdown, setRiderDropDown] = useState();
  const [loading, setLoading] = useState(false);
  const [lab, setLabDropDown] = useState();

  const dispatch = useDispatch();

  const { placedOrder } = useSelector((state) => state.newOrderReducer);

  useEffect(() => {
    let options = props.vendor?.map((lab) => ({
      label: lab?.name,
      value: lab?.ref_id,
    }));
    setLabDropDown(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let options = props.rider?.map((ride) => ({
      label: ride?.dispatch_name,
      value: ride?.dispatch_id,
    }));
    setRiderDropDown(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [successModal, setSuccessModal] = useState(false);

  const navigate = useNavigate();
  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  let neworder;
  if (localStorage.getItem("newOrder"))
    neworder = JSON.parse(localStorage.getItem("newOrder"));

  const colors = () => {
    if (neworder?.title === "BLOOD") return "#B70303";
    if (neworder?.title === "OXYGEN") return "#0779F7";
    if (neworder?.title === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const badgeColor = () => {
    if (neworder?.title === "BLOOD") return "#FDE0E0";
    if (neworder?.title === "OXYGEN") return "#E0EFFD";
    return "#F0FDE0";
  };

  return (
    <div className={style.container}>
      <Formik
        initialValues={
          props.title === "Blood"
            ? {
                // bloodbank: "",
                dispatch_id: "",
                blood: props.blood,
                order_by: props.order_by,
                agent: props.agent,
                agentType: props.agentType,
                patient: props.patient,
                bloodtype: props.bloodtype,
                genotype: props.genotype,
                crossmatch: props.crossmatch,
                genotyping: props.genotyping,
                payment: props.payment,
                hmo: props.hmo,
                doctor: props.doctor,
                usage: props.usage,
                schedule_date: props.schedule_date,
                schedule_time: props.schedule_time,
                qty: props.qty,
                temperature: props.temperature,
                security: props.security,
                title: props.title,
              }
            : {
                // dispatch_id: "",
                blood: props.blood,
                order_by: props.order_by,
                agent: props.agent,
                agentType: props.agentType,
                patient: props.patient,
                name: props.name,
                doctor: props.doctor,
                hmo: props.hmo,
                payment_type: props.payment_type,
                usage: props.usage,
                schedule_date: props.schedule_date,
                schedule_time: props.schedule_time,
                qty: props.qty,
                title: props.title,
                vendor: "",
                product_id: props.product_id,
              }
        }
        validate={(values) => {
          const errors = {};
          if (!values.bloodbank && props.title === "Blood") {
            errors.bloodbank = "Select a vendor";
          }
          if (!values.vendor && props.title === "Oxygen") {
            errors.vendor = "Select a vendor";
          }
          if (!values.dispatch_id) {
            errors.dispatch_id = "Select a dispatch";
          }
          return errors;
        }}
        onSubmit={async (values) => {
          setLoading(true); // Start loading before order submission
          try {
            props.title === "Blood" &&
              (values.bloodbank = values.bloodbank.value);
            props.title === "Oxygen" && (values.vendor = values.vendor.value);
            values.dispatch_id = values.dispatch_id.value;

            const response = await dispatch(placeOrder(values)); // Await dispatch action

            if (response?.status === 300) {
              failedOrder();
            } else {
              orderCreateSuccessToast();
              dispatch(addNewOrder());
              setTimeout(() => {
                navigateFunc();
              }, 3000);
            }
          } catch (error) {
            failedOrder(); // Handle error state
          } finally {
            setLoading(false); // Reset loading state
          }
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <form>
            <div className={style.innerContainer}>
              {/* Vendor Select Field */}
              {props.title === "Blood" ? (
                <div className={`${style.formInput1} shadow-none`}>
                  {errors.bloodbank && touched.bloodbank ? (
                    <p className="text-danger mt-1 mx-2">{errors.bloodbank}</p>
                  ) : null}

                  <SelectField
                    className={style.input}
                    name="bloodbank"
                    options={lab}
                    onChange={setFieldValue}
                    value={values?.vendor}
                    placeholder="Find vendor "
                    onBlur={handleBlur}
                  />
                </div>
              ) : (
                <div className={`${style.formInput1} shadow-none`}>
                  {errors.vendor && touched.vendor ? (
                    <p className="text-danger mt-1 mx-2">{errors.vendor}</p>
                  ) : null}

                  <SelectField
                    className={style.input}
                    name="vendor"
                    options={lab}
                    onChange={setFieldValue}
                    value={values?.vendor}
                    placeholder="Find Vendor"
                    onBlur={handleBlur}
                  />
                </div>
              )}

              <TbArrowsLeftRight size={30} color={props.color} />

              {/* Dispatch Rider Select Field */}
              <div className={`${style.formInput1} shadow-none`}>
                {errors.dispatch_id && touched.dispatch_id ? (
                  <p className="text-danger mt-1 mx-2">{errors.dispatch_id}</p>
                ) : null}
                <SelectField
                  className={style.input}
                  name="dispatch_id"
                  options={riderDropdown}
                  onChange={setFieldValue}
                  value={values?.rider}
                  placeholder="Find Dispatch Rider"
                  onBlur={handleBlur}
                />
              </div>

              {/* Submit Button */}
              <div className={style.content}>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    className={style.button}
                    onClick={handleSubmit}
                    style={{
                      border: `1px solid ${props.border}`,
                      color: props.textColor,
                    }}
                  >
                    {loading ? (
                      <SyncOutlined spin style={{ fontSize: 20 }} />
                    ) : (
                      "PROCESS"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      {/* create MODAL */}
      {/* <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width='60vw'
        height='50vh'
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button='COMPLETE'
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      /> */}
    </div>
  );
};

export default ProcessCardSingle;
