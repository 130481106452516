import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./Quotation.module.css";
import { seeAllQuotation } from "../../redux/admin/actions/admin.actions";
import CircularLoader from "../../components/Loader/CircularLoader";
import { AiFillMedicineBox } from "react-icons/ai";
import { GiGunStock } from "react-icons/gi";
import { FaHeartPulse } from "react-icons/fa6";
import Pagination from "../../components/paginationCopy/Pagination";
import Quote from "./Quote";
import View from "./View";
import Search from "../../components/search/Search";
import { FcCancel } from "react-icons/fc";
import axiosClient from "../../service/axios";
import { sendFinance, financeFailed } from "../../components/NotificationToast";
import { SyncOutlined } from "@ant-design/icons";

const Quotation = () => {
  const dispatch = useDispatch();
  const { seeQuotation } = useSelector((state) => state.adminReducer);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  // Search handler
  useEffect(() => {
    setData(
      seeQuotation?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [seeQuotation, search]);

  const removeItemFromTable = (id) => {
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 9;
  const pagesVisited = pageNumber * size;
  const pageCount = Math.ceil(data?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const badgeColor = (icon) => {
    if (icon === "quip") return "#78A55A";
    if (icon === "stock") return "#ffd4c2";
    return "#F0FDE0";
  };

  const icons = (icon) => {
    if (icon === "stock") return <FaHeartPulse size={25} color="#B70303" />;
    if (icon === "quip") return <AiFillMedicineBox size={25} color="#fff" />;
    return <GiGunStock size={25} color="#78A55A" />;
  };

  useEffect(() => {
    setLoading(true);
    dispatch(seeAllQuotation());
    setLoading(false);
  }, [dispatch]);

  const handleRowClick = (id) => {
    setIsOpen(true);
    setId(id);
  };

  const handleView = (id) => {
    setIsView(true);
    setId(id);
  };

  const handleFinance = async (id) => {
    setShowSpinner(true);
    try {
      const response = await axiosClient.get(
        `/lite/v2/api/admin/quotations/sendtofinance/` + id
      );

      if (response && response.data.status === 200) {
        sendFinance();
        setShowSpinner(false);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else if (response && response.data.status === 500) {
        financeFailed();
      }
    } catch (error) {
      console.log("Error in send to finance", error);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between m-3">
          <div></div>
          <Search placeholder="Find any order here" onChange={handleChange} />
        </div>

        <p className={style.title}>See Quotation</p>
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <CircularLoader />
          </div>
        )}
        {seeQuotation?.length > 0 ? (
          <>
            <table>
              <thead className={style.header}>
                <tr>
                  <th>Id</th>
                  <th>Hospital</th>
                  <th>Product Type</th>
                  <th>Needed in</th>
                  <th>Phone</th>
                  <th>Time</th>
                  <th>Decline Reason</th>
                  <th>OVA</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Finance Approval</th>
                </tr>
              </thead>
              <tbody className={style.text}>
                {data
                  ?.sort((a, b) => (a.tym > b.tym ? -1 : 1))
                  ?.slice(pagesVisited, pagesVisited + size)
                  ?.map((row, index) => (
                    <tr key={index} className={style.rowWithBorder}>
                      <td>{row.id}</td>
                      <td>{row.name}</td>
                      <td className={style.iconTextContainer}>
                        <div
                          className={`${style.icon}`}
                          style={{ background: badgeColor(row.quipOrStock) }}
                        >
                          {icons(row.quipOrStock)}
                        </div>
                        <span className={style.quipOrStockText}>
                          {row.quipOrStock}
                        </span>
                      </td>

                      <td>{row.order_time}</td>
                      <td>{row.fone}</td>
                      <td>{row.tym}</td>
                      <td>{row.decline_reason}</td>
                      <td>{row.since}</td>
                      <td>{row.status}</td>
                      {/* <td className={style.finOva}>
                        <div>
                          {row.account === "" || row.account === null ? (
                            <div>
                              {" "}
                              <FcCancel />
                            </div>
                          ) : (
                            <div
                              className={style.sendToOvaButton}
                              onClick={() => handleRowClick(row.id)}
                            >
                              SEND TO OVA
                            </div>
                          )}
                        </div>
                      </td> */}

                      <td className={style.finOva}>
                        {row.sendova === null ||
                        row.account === null ||
                        row.account === "" ? (
                          <div
                            className={style.sendToOvaButton}
                            onClick={() => handleRowClick(row.id)}
                          >
                            SEND TO OVA
                          </div>
                        ) : (
                          row.sendova !== null &&
                          row.account !== null && (
                            <div
                              className={style.viewButton}
                              onClick={() => handleView(row.id)}
                            >
                              View
                            </div>
                          )
                        )}
                      </td>

                      <td>
                        {row.account === "" || row.account === null ? (
                          <div
                            className={style.sendToFinanceButton}
                            onClick={() => handleFinance(row.id)}
                          >
                            {showSpinner ? (
                              <SyncOutlined spin />
                            ) : (
                              "SEND TO FINANCE"
                            )}
                          </div>
                        ) : (
                          <div> {row.account}</div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {seeQuotation?.length > size && (
              <Pagination pageCount={pageCount} onPageChange={changePage} />
            )}
          </>
        ) : (
          <p className="text-center">No record found</p>
        )}
      </div>
      {isOpen && (
        <Quote
          setIsOpen={setIsOpen}
          id={id}
          onProceed={removeItemFromTable}
          data={data}
        />
      )}

      {isView && (
        <View
          setIsView={setIsView}
          id={id}
          onProceed={removeItemFromTable}
          data={data}
        />
      )}
    </>
  );
};

export default Quotation;
