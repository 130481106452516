import React, { useEffect, useState } from "react";
import style from "./QuipCard.module.scss";
import { FaHandPaper } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import Checkbox from "@mui/material/Checkbox";
import Naira from "react-naira";
import {
  fetchProducts,
  fetchQuipProducts,
  saveStockbank,
} from "../../redux/new-order/actions/new-orders.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../Modal/ConfirmationModal";
import { hospitalList } from "../../redux/orders/actions/orders.actions";
import { SelectField } from "../Form/SelectField";
import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import CircularLoader from "../Loader/CircularLoader";
import { GiSensuousness } from "react-icons/gi";
import Sensitive from "../../components/modalInstruction/Sensitive";

const QuipCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const [loading, setLoading] = useState(true);
  const [sensitive, setSensitive] = useState(false);

  const { orderType, quipProducts } = useSelector(
    (state) => state.newOrderReducer
  );

  const quipProductss = quipProducts?.products;

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    if (orderType?.cardText === "QUIP") return "#DA621B";
  };

  const [hospitalDropDown, setHospitalDropDown] = useState();

  useEffect(() => {
    dispatch(fetchQuipProducts())
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    dispatch(hospitalList()).then((response) => {
      let options = response.data
        .sort((a, b) => a.label - b.label)
        .map((hosp) => ({
          label: `${hosp.Name} (${hosp.State}-${hosp.City})`,
          value: hosp.Hospital_ID,
          sensitive: hosp.sensitive_cus,
        }));
      setHospitalDropDown(options);
      // console.log("name:", response.data[0].Name);
    });
  }, [dispatch]);

  return (
    <div className={style.container}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color={colors()}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            hospital detail
          </h3>
          <h4 className={style.requestHeader1} style={{ color: colors() }}>
            select hospital
          </h4>
          <Formik
            initialValues={{
              hospital: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.hospital) {
                errors.hospital = "This field is mandatory";
              }
              return errors;
            }}
            onSubmit={(values) => {
              const lowerCaseOrderType =
                typeof orderType?.cardText === "string"
                  ? orderType?.cardText.toLowerCase()
                  : "";
              navigate(`/place-order/quip-selection/${values.hospital.value}`, {
                state: {
                  pro: lowerCaseOrderType,
                },
              });
            }}
          >
            {({
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              resetForm,
              setFieldValue,
              handleBlur,
              errors,
              touched,
            }) => (
              <form>
                <div className={`${style.formInput} shadow-none`}>
                  <SelectField
                    className={style.input}
                    name="hospital"
                    options={hospitalDropDown}
                    onChange={setFieldValue}
                    value={values?.hospital}
                    placeholder="Find a hospital here"
                    onBlur={handleBlur}
                  />
                  {values.hospital?.sensitive ? (
                    <div onClick={() => setSensitive(true)}>
                      <GiSensuousness
                        size={40}
                        className={style.sensitiveIcon}
                        style={{
                          color: colors(),
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {errors.hospital && touched.hospital ? (
                    <p className="text-danger mt-1 mx-2">{errors.hospital}</p>
                  ) : null}
                </div>

                <div
                  type="submit"
                  name="Scheduled"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className={style.next}
                >
                  {"NEXT"}
                </div>

                <Sensitive
                  value={values.hospital?.sensitive}
                  sensitive={sensitive}
                  setSensitive={setSensitive}
                />
              </form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default QuipCard;
