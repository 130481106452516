import React, { useState } from "react";
import { MdWaterDrop } from "react-icons/md";
import { HiLocationMarker, HiOutlineViewGridAdd } from "react-icons/hi";
import { GiScubaTanks } from "react-icons/gi";
import { FaSyringe } from "react-icons/fa";
import { SyncOutlined } from "@ant-design/icons";
// import { PiBroadcastDuotone } from "react-icons/pi";

import style from "./nerve.module.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../Modal/ConfirmationModal";
import StockbankInstruction from "../../orderInstruction/stockbankInstruction";
import BloodInstruction from "../../orderInstruction/BloodInstruction";
import OxygenInstruction from "../../orderInstruction/OxygenInstruction";
import { constructOrderDate } from "../../../helper/DateFormatter";
import { processOrder } from "../../../redux/orders/actions/orders.actions";
import { useDispatch } from "react-redux";
import { FiSettings } from "react-icons/fi";

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const Nerve = ({
  order_id,
  b_type,
  genotype,
  blood,
  usage_info,
  order_by,
  order_pick,
  adm_state,
  complete_tym,
  complete_agent,
  order_state,
  Request_Type,
  scheduled_tym,
  scheduled_date,
  order_amt,
  address,
  city,
  state,
  fone,
  title,
  oxygen,
  stockbank,
  id,
  payment,
  payment_type,
  qty,
  tym,
  name,
  order_source,
  urgency,
  product,
  order_type,
  size,
  created_at,
  personnel_name,
}) => {
  const [openModal, setopenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log("idddddd",order_id)

  const bgColor = () => {
    if (title === "blood") return "#FDE0E0";
    if (title === "oxygen") return "#E0EFFD";
    if (title === "Quip") return "#ffd4c2";
    return "#F0FDE0";
  };

  const icon = () => {
    if (title === "blood") return <MdWaterDrop size={25} color="#B70303" />;
    if (title === "oxygen") return <GiScubaTanks size={25} color="#2457C5" />;
    if (title === "Quip") return <FiSettings size={25} color="#c7460f" />;
    return <FaSyringe size={25} color="#78A55A" />;
  };

  const color = () => {
    if (title === "blood") return "#B70303";
    if (title === "oxygen") return "#2457C5";
    if (title === "Quip") return "#c7460f";
    return "#78A55A";
  };

  const navigate = useNavigate();

  // const time = Math.floor(new Date().getTime() / 1000);

  // console.log("tome", time);

  const dispatch = useDispatch();

  const data = {
    title: title === "blood" ? "Blood" : title,
    order_by: order_by,
    bloodtype: b_type,
  };
  const processHandler = async () => {
    dispatch(processOrder(data));
    setLoading(true);
    await delay(3000);
    setLoading(false);
    if (title === "oxygen") {
      navigate(`/process-order/${id}`);
    } else {
      navigate(`/process-order/${order_id}`);
    }
  };

  const modalPerformance = () => {
    if (title === "StockBank") {
      navigate(`/stockbank/${order_id}`);
    } else if (title === "Quip") {
      // setopenModal(true);
      navigate(`/quip/${order_id}`);
    } else {
      setopenModal(true);
    }
  };

  return (
    <div className={style.container}>
      <div onClick={modalPerformance}>
        <div className={style.innerContainer}>
          <div className={style.content}>
            <div className={style.icon} style={{ background: bgColor() }}>
              {icon()}
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>Product Name</p>

              {title === "blood" ? blood : title === "oxygen" ? size : title}
            </div>
          </div>

          <div className={style.content}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <HiOutlineViewGridAdd size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>QUANTITY</p>
              <p className={style.text}>
                {title === "blood" ? order_amt : qty}
              </p>
            </div>
          </div>
          <div className={style.content}>
            <div className="d-flex flex-column">
              <p className={style.header}>HOSPITAL NAME</p>
              <p className={style.text}>{name}</p>
            </div>
          </div>
          <div className={`${style.content} ${style.address}`}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <HiLocationMarker size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>HOSPITAL ADDRESS</p>
              <p className={style.text}>{` ${address}, ${city}, ${state}`}</p>
            </div>
          </div>

          <div className={`${style.content} ${style.address}`}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <HiLocationMarker size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>SOURCE</p>
              <p className={style.text}>{order_source}</p>
            </div>
          </div>

          <div className={style.content}>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className={style.button}
                style={{ border: `2px solid ${bgColor()}`, color: color() }}
                onClick={modalPerformance}
              >
                process
              </Button>
            </div>
          </div>
        </div>
        <div className={style.time}>Last update :{constructOrderDate(tym)}</div>
      </div>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        bgcolor="background.paper"
        width="80vw"
        handleClose={() => setopenModal(false)}
        closemodal={() => setopenModal(false)}
        instruction={
          title === "StockBank" ? (
            <StockbankInstruction
              status={title}
              button2={loading ? <SyncOutlined spin /> : `PROCESS ORDER`}
              orderId={order_id}
              category={title}
              headerColor={color()}
              categoryBg={bgColor()}
              categoryColor={color()}
              btnColor={color()}
            />
          ) : title === "blood" ? (
            <BloodInstruction
              button2={loading ? <SyncOutlined spin /> : `PROCESS ORDER`}
              orderId={`LB${order_id}`}
              product="Blood"
              bloodGroup={b_type}
              quantity={order_amt}
              vendor={name}
              reason={usage_info}
              request={personnel_name}
              address={address}
              phone={fone}
              payment={payment_type}
              time={constructOrderDate(tym)}
              category={title}
              headerColor={color()}
              categoryBg={bgColor()}
              categoryColor={color()}
              btnColor={color()}
              onClickYes={processHandler}
            />
          ) : (
            <OxygenInstruction
              status={title}
              button2={loading ? <SyncOutlined spin /> : `PROCESS ORDER`}
              orderId={id}
              product={size}
              quantity={qty}
              payment={payment_type}
              hospital={personnel_name}
              address={`${address}, ${city}, ${state}`}
              category={title}
              time={constructOrderDate(tym)}
              headerColor={color()}
              phone={fone}
              categoryBg={bgColor()}
              categoryColor={color()}
              btnColor={color()}
              onClickYes={processHandler}
            />
          )
        }
      />
    </div>
  );
};

export default Nerve;
