/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

// @labX URL
export const LABX_URL = "/lite/v2/api/v3/orders";

// @ussd URL
export const USSD_URL = "/lite/v2/api/labx/boat";

// @call URL
export const CALL_URL = "/lite/v2/api/labx/boat";

// @boat URL
export const BOAT_URL = "/lite/v2/api/labx/boat";

// @ongoing URL
export const ONGOING_URL = "/lite/v2/api/v3/completed/orders";

// @overdue URL
export const OVERDUE_URL = "/lite/v2/api/ongoing/overdue";

// @completed URL
export const COMPLETED_URL = "/lite/v2/api/v3/completed/orders";

// @newOrder URL
export const NEW_ORDER_URL = "/lite/v2/api/labx/boat";

// @hospital URL
export const HOSPITAL_URL = "/lite/v2/api/order/hospitallist";
export const HOSPITAL_LIST_URL = "/lite/v2/api/order/hospitallist/stockbank";

// @dispatch URL
export const DISPATCH_URL = "/lite/v2/api/dispatch/";

// @lab URL
export const LAB_URL = "/lite/v2/api/order/bloodbanklistonly";

// @close URL
export const CLOSE_ORDER_URL = "/lite/v2/api/v3/close";

// @return URL
export const RETURN_ORDER_URL = "/lite/v2/api/v3/return";

// @return URL
export const COMPLAIN_ORDER_URL = "/lite/v2/api/v3/complain";

// @processOrder
export const PROCESS_ORDER_URL = "/lite/v2/api/v3/precheck";

//  @get oxygen list
export const OXYGEN_URL = "/lite/v2/api/order/oxygenlist";

//  @track order
export const TRACK_ORDER_URL = "/lite/v2/api/v3/status/order/";

//  @order price
export const ORDER_PRICE_URL = "/lite/v2/api/v3/pricecheck";

export const RIDER_URL = "/lite/v2/api/v3/dispatch/tracking";

export const STOCKBANK_URL = "/lite/v2/api/v3/stockbankorder/product/";

export const QUIP_URL = "/lite/v2/api/v3/quiporder/product/";

export const NOTIFICATION_URL = "/lite/v2/api/v3/notifications";

export const VIEW_NOTIFICATION = "/lite/v2/api/v3/notifications";

export const UPDATE_ORDER_URL = "/lite/v2/api/v3/edit/ongoing";
