import React from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import CircularLoader from "../Loader/CircularLoader";
import { useState } from "react";
import { RiTimeFill } from "react-icons/ri";
import { BiCurrentLocation } from "react-icons/bi";
import { FaLocationArrow } from "react-icons/fa";
import dateFormat, { secondsToHms } from "../../helper/DateFormatter";
import style from "./map.module.scss";
import Stepnavigation from "../orderProcess/Stepnavigation";

import "./Step.css";

const center = {
  lat: 6.4471696,
  lng: 3.5178536,
};

const libraries = ["places"];

const Map = ({
  orderOrigin,
  orderDestination,
  eta,
  vendorName,
  dispatch,
  arrived,
  delivered,
  riderAddress,
  riderName,
  title,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsresponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [timing, setTiming] = useState("");

  let track;
  if (localStorage.getItem("trackOrder")) {
    track = JSON.parse(localStorage.getItem("trackOrder"));
  }

  // /** @type React.MutableRefObject<HTMLInputElement */
  // const originRef = useRef();

  // /** @type React.MutableRefObject<HTMLInputElement */
  // const destinationRef = useRef();

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <CircularLoader />
      </div>
    );
  }

  const calculateRoute = async () => {
    if (orderOrigin === "" || orderDestination === "") {
      return;
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: orderOrigin,
      destination: orderDestination,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsresponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.value);
    setTiming(results.routes[0].legs[0].duration.text);
  };

  if (isLoaded) {
    calculateRoute();
  }

  const cT = new Date();

  return (
    <div className={style.map}>
      {isLoaded && (
        <>
          <div className={style.lite}>
            Pick up and Drop off (distance) ::{" "}
            {title === "true"
              ? secondsToHms(duration + 1800)
              : secondsToHms(duration)}
            {title === "true" && "(Cross-Matching)"}
          </div>

          <button
            onClick={() => map.panTo(calculateRoute())}
            className={style.recenter}
          >
            <FaLocationArrow />
          </button>
          <div className="d-flex m-3">
            <div className={style.orderId}>
              <RiTimeFill size={25} className="me-2" />
              E.T.A:{" "}
              <span className={style.text}>
                {" "}
                {dateFormat.TimeOnly(
                  cT.setSeconds(
                    cT.getSeconds() +
                      parseInt(title === "true" ? duration + 1800 : duration)
                  )
                )}{" "}
              </span>
            </div>
          </div>
          <div className="d-flex m-3">
            <div className={style.orderId}>
              <BiCurrentLocation size={25} className="me-2" />
              STATUS: <span className={style.text}> {track?.order_state}</span>
            </div>
          </div>
          <div className={style.status}>
            <Stepnavigation
              vendorName={vendorName}
              dispatch={dispatch}
              arrived={arrived}
              delivered={delivered}
              riderAddress={riderAddress}
              riderName={riderName}
            />
          </div>
        </>
      )}
      <div className={style.googleMap}>
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          {/* <InfoWindow onLoad={onLoad} position={position}>
            <div style={divStyle}>
              <h1>InfoWindow</h1>
            </div>
          </InfoWindow> */}
          <Marker position={center} />

          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default Map;
