import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./Bid.module.css";
import { useNavigate } from "react-router-dom";
import {  seeBidQuotation } from "../../redux/admin/actions/admin.actions";
import CircularLoader from "../../components/Loader/CircularLoader";
import { constructOrderDate } from "../../helper/DateFormatter";
import { FaHeartPulse } from "react-icons/fa6";
import { AiFillMedicineBox } from "react-icons/ai";
import { GiGunStock } from "react-icons/gi";
import Pagination from "../../components/paginationCopy/Pagination";
import Search from "../../components/search/Search";

const Bid = () => {
  const dispatch = useDispatch();
  const { seeBid }  = useSelector((state) => state.adminReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  // Search handler
  useEffect(() => {
    setData(
      seeBid?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [seeBid, search]);


  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 9;
  const pagesVisited = pageNumber * size;
  const pageCount = Math.ceil(data?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };



  const badgeColor = (icon) => {
    if (icon === "quip") return "#78A55A";
    if (icon === "stock") return "#ffd4c2";
    return "#F0FDE0";
  };

  const icons = (icon) => {
    if (icon === "stock") return <FaHeartPulse size={25} color="#B70303" />;
    if (icon === "quip") return <AiFillMedicineBox size={25} color="#fff" />;
    return <GiGunStock size={25} color="#78A55A" />;
  };

  // console.log("seeBBBBBBBBBB", seeBid);

  useEffect(() => {
    setLoading(true);
    dispatch(seeBidQuotation());
    setLoading(false);
  }, [dispatch]);

  const Navigate = useNavigate();

  const viewBid = (id) => {
    // console.log("id", id)


    Navigate(`/view-bid/${id}`)
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-between m-3">
          <div></div>
          <Search placeholder="Find any order here" onChange={handleChange} />
        </div>

        <p className={style.title}>Manage Bids</p>
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <CircularLoader />
          </div>
        )}
        {seeBid?.length > 0 ? (
          <>
            <table>
              <thead className={style.header}>
                <tr>
                 <th>Request-ID</th>
                  <th>Hospital</th>
                  <th>Product Type</th>
                  <th>Needed in</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className={style.text}>
                {data
                  ?.sort((a, b) => (a.tym > b.tym ? -1 : 1))
                  ?.filter((item) => item.data !== data)
                  ?.slice(pagesVisited, pagesVisited + size)
                  ?.map((row, index) => (
                    <tr key={index} className={style.rowWithBorder}>
                      {/* <td>{pageNumber * size + index + 1}</td> */}
                      <td>{row.id}</td>
                      <td>{row.name}</td>
                      <td className={style.iconTextContainer}>
                        <div
                          className={`${style.icon}`}
                          style={{ background: badgeColor(row.quipOrStock) }}
                        >
                          {icons(row.quipOrStock)}
                        </div>
                        <span className={style.quipOrStockText}>
                          {row.quipOrStock}
                        </span>
                      </td>
                      <td>{row.order_time}</td>
                      <td>{`${constructOrderDate(row.tym)} `}</td>
                      <td className={style.processbtn}>
                      <div
                          className={style.sendToOvaButton}
                          onClick={() => viewBid(row.id)}
                        >
                          VIEW BIDS
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {seeBid?.length > size && (
              <Pagination pageCount={pageCount} onPageChange={changePage} />
            )}
          </>
        ) : (
          <p className="text-center">No record found</p>
        )}
      </div>
    </>
  );
};

export default Bid;
