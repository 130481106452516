import React, { useEffect, useState } from "react";
import ProcessCard from "../../../components/orders/process/ProcessCard";
import style from "./Process.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
// import { makeStyles } from "@mui/material/styles";
import ProcessCardSplit from "../../../components/orders/process/ProcessCardSplit";
import ProcessCardSingle from "../../../components/orders/process/ProcessCardSingle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { labXOrders } from "../../../redux/orders/actions/orders.actions";
import {
  addNewOrder,
  placeOrder,
} from "../../../redux/new-order/actions/new-orders.actions";
// import { SuccessModal } from "../../../components/Modal/SuccessModal";
// import MessageInstruction from "../../../components/modalInstruction/MessageInstruction";
import { orderCreateSuccessToast } from "../../../components/NotificationToast";
import { Col, Row } from "react-bootstrap";

// const useStyles = makeStyles((theme) => ({
//   root: {},
// }));

const ProcessNewOxygen = () => {
  // const [successModal, setSuccessModal] = useState(false);
  // const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState({ 1: false, 2: false, 3: false });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  useEffect(() => {
    dispatch(labXOrders(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const { placedOrder } = useSelector((state) => state.newOrderReducer);

  // console.log("nerve", nerve);

  let precheck;
  if (localStorage.getItem("precheck"))
    precheck = JSON.parse(localStorage.getItem("precheck"));

  let vendor = precheck?.vendor;
  let rider = precheck?.Dispatch;
  let status = precheck?.status;

  let neworder;
  if (localStorage.getItem("newOrder"))
    neworder = JSON.parse(localStorage.getItem("newOrder"));

  const response1 = {
    order_by: neworder?.order_by,
    agent: userDetails?.id,
    agentType: neworder?.agentType,
    vendor: vendor && vendor[1]?.ref_id,
    patient: neworder?.patient,
    dispatch_id: rider ? rider[0]?.dispatch_id : "",
    payment_type: neworder?.payment_type,
    qty: neworder?.order_amt,
    product_id: neworder?.cylinderSize,
    title: neworder?.title === "OXYGEN" ? "Oxygen" : neworder?.title,
    hmo: neworder?.hmo,
  };

  const response2 = {
    order_by: neworder?.order_by,
    agent: userDetails?.id,
    agentType: neworder?.agentType,
    patient: neworder?.patient,
    vendor: vendor && vendor[1]?.ref_id,
    dispatch_id: rider ? rider[1]?.dispatch_id : "",
    payment_type: neworder?.payment_type,
    qty: neworder?.order_amt,
    product_id: neworder?.cylinderSize,
    title: neworder?.title === "OXYGEN" ? "Oxygen" : neworder?.title,
    hmo: neworder?.hmo,
  };

  const handleProcess1 = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, 1: true }));
    await dispatch(placeOrder(response1));
    setLoading((prevLoading) => ({ ...prevLoading, 1: false }));
    dispatch(addNewOrder());
    orderCreateSuccessToast();
    setTimeout(() => {
      navigateFunc();
    }, 3000);
  };

  const handleProcess2 = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, 2: true }));
    await dispatch(placeOrder(response2));
    setLoading((prevLoading) => ({ ...prevLoading, 2: false }));
    dispatch(addNewOrder());
    orderCreateSuccessToast();
    setTimeout(() => {
      navigateFunc();
    }, 3000);
  };

  // useEffect(() => {
  //   dispatch(labXOrders(id));
  // }, [id]);

  let checked;
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ _theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "black",
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props?.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
  };

  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  const colors = () => {
    if (neworder?.title === "BLOOD") return "#B70303";
    if (neworder?.title === "OXYGEN") return "#0779F7";
    if (neworder?.title === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const badgeColor = () => {
    if (neworder?.title === "BLOOD") return "#FDE0E0";
    if (neworder?.title === "OXYGEN") return "#E0EFFD";
    return "#F0FDE0";
  };

  return (
    <div style={{ width: "97%" }}>
      {status === 300 ? (
        <Row className='d-flex align-items-center mb-4'>
          <Col md={1}>
            <IoIosArrowDropleftCircle
              size={50}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              color={colors()}
            />
          </Col>
          <Col md={8}>
            <p className={style.warning}>
              No Match Available. Please try again
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <Row className='d-flex align-items-center mb-4'>
            <Col md={1}>
              <IoIosArrowDropleftCircle
                size={50}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
                color={colors()}
              />
            </Col>
            <Col md={8}>
              <p className={style.header}>BEST MATCH</p>
            </Col>
          </Row>

          {vendor[0] && rider[0] && (
            <ProcessCard
              id='1'
              name={vendor[0]?.name}
              address={vendor[0]?.address}
              dispatch_name={rider[0]?.dispatch_name}
              dispatchAddress=''
              distance={`${parseInt(vendor[0]?.distance * (65 / 60))} Mins`}
              onClick={handleProcess1}
              color={colors()}
              borderColor={colors()}
              bgColor={colors()}
              loading={loading[1]}
            />
          )}

          {vendor[1] && rider[1] && (
            <ProcessCard
              id={"2"}
              name={vendor[1]?.name}
              address={vendor[1]?.address}
              dispatch_name={rider[1]?.dispatch_name}
              dispatchAddress=''
              distance={`${parseInt(vendor[1]?.distance * (65 / 60))} Mins`}
              onClick={handleProcess2}
              color={colors()}
              borderColor={colors()}
              bgColor={colors()}
              loading={loading[2]}
            />
          )}

          <p className={style.header}>CUSTOM MATCH</p>

          <RadioGroup name='use-radio-group' defaultValue='single'>
            <MyFormControlLabel
              value='single'
              label='SINGLE DELIVERY'
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: colors(),
                    },
                  }}
                />
              }
            />
            <ProcessCardSingle
              order_by={neworder?.order_by}
              product_id={neworder?.cylinderSize}
              patient={neworder?.patient}
              hmo={neworder?.hmo}
              agent={userDetails?.id}
              agentType={neworder?.agentType}
              qty={neworder?.order_amt}
              name={neworder?.blood}
              payment_type={neworder?.payment_type}
              usage={neworder?.usage}
              schedule_date={neworder?.schedule_date}
              schedule_time={neworder?.schedule_time}
              title={neworder?.title === "OXYGEN" ? "Oxygen" : neworder?.title}
              color={colors()}
              border={colors()}
              textColor={colors()}
              vendor={vendor}
              rider={rider}
            />
            <MyFormControlLabel
              value='split'
              label='SPLIT DELIVERY'
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: colors(),
                    },
                  }}
                />
              }
            />
            {/* {split.map(() => (
          <> */}
            <ProcessCardSplit
              order_by={neworder?.order_by}
              product_id={neworder?.cylinderSize}
              patient={neworder?.patient}
              hmo={neworder?.hmo}
              agent={userDetails?.id}
              agentType={neworder?.agentType}
              name={neworder?.blood}
              payment_type={neworder?.payment_type}
              usage={neworder?.usage}
              schedule_date={neworder?.schedule_date}
              schedule_time={neworder?.schedule_time}
              qty={neworder?.order_amt}
              title={neworder?.title === "OXYGEN" ? "Oxygen" : neworder?.title}
              color={colors()}
              border={colors()}
              textColor={colors()}
              vendor={vendor}
              rider={rider}
            />
            {/* </>
        ))} */}
          </RadioGroup>
        </>
      )}

      {/* create MODAL */}
      {/* <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width='60vw'
        height='50vh'
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button='COMPLETE'
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      /> */}
    </div>
  );
};

export default ProcessNewOxygen;
