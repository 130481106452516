import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import style from "./NerveUsage.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import axiosClient from "../../service/axios";
import { SyncOutlined } from "@ant-design/icons";

function NerveUsage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const { orderType } = useSelector((state) => state.newOrderReducer);

 let order_id;
  if (localStorage.getItem("order_by")) {
    order_id = JSON.parse(localStorage.getItem("order_by"));
  }
  console.log("order_id", order_id);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const processHandler = async (e) => {
    e.preventDefault(); 

    const data = {
      message: message,
      order_by: order_id,
    };

    setLoading(true);
    try {
      const response = await axiosClient.post(
        "/lite/v2/api/v3/nerve/usages/question",
        data
      );
      if (response.status === 200) {
        setLoading(false);
        navigate("/order-details");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            WHY ARE YOU NOT USING NERVE?
          </h3>

          <Formik initialValues={{ message: "" }}>
            {({}) => (
              <Form onSubmit={processHandler}>
                <Form.Group className={`${style.group} mb-3`}>
                  {/* <p className={style.optional}>(optional)</p> */}
                  <Form.Control
                    type="text"
                    placeholder="Write your reason here"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                    className={`${style.input} rounded p-2`}
                    required
                  />
                </Form.Group>

                <button
                  type="submit"
                  variant="primary"
                  className={`${style.button} btn`}
                  style={{
                    background: colors(),
                    border: `1px solid ${colors()}`,
                  }}
                >
                  {loading ? <SyncOutlined spin /> : `Proceed`}
                </button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default NerveUsage;
