import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import styles from "./Reason.module.scss";
import axiosClient from "../../service/axios";
import { SyncOutlined } from "@ant-design/icons";
import { reasonToast } from "../NotificationToast";

function Reason({ reason, setReason, title, orderId }) {
  const [loading, setLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState(""); // State for selected reason

  const handleModalClose = () => {
    setReason(false);
  };

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
        orderId: orderId,
        feedback: selectedReason,
        title: title,
        reason: document.getElementById("reason").value
    }
    setLoading(true);

    try {
      const response = await axiosClient.post("/lite/v2/api/v3/late/order", data);
      if(response.status === 201) {
        setLoading(false);
        setReason(false);
        reasonToast();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Dialog open={reason} onClose={handleModalClose}>
      <DialogContent style={{ paddingBottom: "0" }}>
        <div className={styles.title}>Reason For Delay</div>

        <div className={styles.formContainer}>
          <form>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="reasons">Why late for delivery?</label>
                <select
                  id="reasons"
                  name="reasons"
                  value={selectedReason}
                  onChange={handleReasonChange}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="delay due to product unavailability">
                    Delay due to product unavailability
                  </option>
                  <option value="late delivery due to long sourcing period">
                    Late delivery due to long sourcing period
                  </option>
                  <option value="all riders occupied with orders">
                    All riders occupied with orders
                  </option>
                  <option value="others">Others</option>
                </select>
              </div>

              {/* Show the textarea only when 'others' is selected */}
              {selectedReason === "others" && (
                <div className={styles.formGroup}>
                  <label htmlFor="reason">
                    Others Specification <span>*</span>
                  </label>
                  <textarea id="reason" name="reason" required />
                </div>
              )}
            </div>
          </form>
        </div>
      </DialogContent>

      <DialogActions style={{ paddingRight: "49px", paddingBottom: "49px" }}>
        <div
          style={{
            cursor: "pointer",
            color: "#253858",
            backgroundColor: "#DFE1E6",
            padding: "10px",
            borderRadius: "3px",
            border: "1px solid #DFE1E6",
            fontSize: "14px",
          }}
          onClick={handleModalClose}
        >
          Cancel
        </div>
        <div
          style={{
            cursor: "pointer",
            color: "#ffffff",
            backgroundColor: "#b70303",
            padding: "10px",
            borderRadius: "3px",
            border: "1px solid #DFE1E6",
            fontSize: "14px",
          }}
          onClick={handleSubmit}
        >
          {loading ? (
            <SyncOutlined
              spin
              style={{
                fontSize: "14px",
              }}
            />
          ) : (
            "Submit"
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default Reason;