// importing actions
import {
  LOAD_LABX_ORDERS,
  LOAD_USSD_ORDERS,
  LOAD_CALL_ORDERS,
  LOAD_BOAT_ORDERS,
  LOAD_ONGOING_ORDERS,
  LOAD_OVERDUE_ORDERS,
  LOAD_COMPLETED_ORDERS,
  LOAD_HOSPITAL_LIST,
  LOAD_HOSPITAL,
  LOAD_DISPATCH_LIST,
  LOAD_LAB_LIST,
  CLOSE_ORDER,
  PROCESS_ORDER,
  LOAD_OXYGEN_LIST,
  TRACK_ORDER,
  ORDER_PRICE,
  LOAD_RIDERS,
  STOCKBANK_PRODUCTS,
  QUIP_PRODUCTS,
  NOTIFICATIONS,
  UPDATE_ORDER,
} from "../../types";

// state
const INITIAL_STATE = {
  labxOrders: { nerve: [], ussd: [], boat: [] },
  ongoing: [],
  overdue: [],
  ussd: { nerve: [], ussd: [], boat: [] },
  call: { nerve: [], ussd: [], boat: [] },
  boat: { nerve: [], ussd: [], boat: [] },
  completed: [],
  hospital: [],
  stockHospital: [],
  labs: [],
  riders: null,
  toBuy: {},
  closed: {},
  precheck: { vendor: [], Dispatch: [] },
  oxygen: [],
  track: {},
  price: {},
  rider: [],
  stockbankProducts: [],
  quipProducts: [],
  notifications: [],
  updateOrder: [],
};

// Redux reducer function
const orderReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case LOAD_LABX_ORDERS:
      return {
        ...state,
        labxOrders: payload,
      };

    case LOAD_USSD_ORDERS:
      return {
        ...state,
        ussd: payload,
      };

    case LOAD_CALL_ORDERS:
      return {
        ...state,
        call: payload,
      };

    case LOAD_BOAT_ORDERS:
      return {
        ...state,
        boat: payload,
      };

    case LOAD_ONGOING_ORDERS:
      return {
        ...state,
        ongoing: payload,
      };

    case LOAD_OVERDUE_ORDERS:
      return {
        ...state,
        overdue: payload,
      };

    case LOAD_COMPLETED_ORDERS:
      return {
        ...state,
        completed: payload,
      };

    case LOAD_HOSPITAL_LIST:
      return {
        ...state,
        hospital: payload,
      };

    case LOAD_HOSPITAL:
      return {
        ...state,
        stockHospital: payload,
      };

    case LOAD_DISPATCH_LIST:
      return {
        ...state,
        riders: payload,
      };

    case LOAD_LAB_LIST:
      return {
        ...state,
        labs: payload,
      };

    case LOAD_OXYGEN_LIST:
      return {
        ...state,
        oxygen: payload,
      };

    case CLOSE_ORDER:
      return {
        ...state,
        closed: payload,
      };

    case PROCESS_ORDER:
      return {
        ...state,
        precheck: payload,
      };

    case TRACK_ORDER:
      return {
        ...state,
        track: payload,
      };

    case ORDER_PRICE:
      return {
        ...state,
        price: payload,
      };

    case LOAD_RIDERS:
      return {
        ...state,
        rider: payload,
      };

    case STOCKBANK_PRODUCTS:
      return {
        ...state,
        stockbankProducts: payload,
      };

    case QUIP_PRODUCTS:
      return {
        ...state,
        quipProducts: payload,
      };

    case NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };

    case UPDATE_ORDER:
      return {
        ...state,
        updateOrder: payload,
      };

    default:
      return state;
  }
};

export default orderReducer;

// const info = [
//   {
//     label: 'PRoDUCTS',
//     Text: 'BLOOD',
//   },
//   {labkle: }
// ]
