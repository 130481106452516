// importing actions
import {
  SAVE_BLOOD_COMPONENT,
  SAVE_BLOOD_GROUP,
  SAVE_HOSPITAL_DETAILS,
  SAVE_ORDER_INFO,
  SAVE_ORDER_QUANTITY,
  SAVE_ORDER_REASON,
  SAVE_ORDER_TYPE,
  SAVE_PAYMENT_METHOD,
  SAVE_REQUEST_TYPE,
  SAVE_CYLINDER_SIZE,
  SAVE_OXYGEN_TYPE,
  SAVE_PATIENT_NAME,
  SAVE_STOCKBANK_DETAILS,
  FETCH_PRODUCTS,
  PLACE_ORDER,
  EXISTING_ORDER,
  BOAT_ORDER,
  SAVE_GENOTYPE,
  SAVE_AGENT_TYPE,
  FETCH_QUIP_PRODUCTS,
} from "../../types";

// state
const INITIAL_STATE = {
  orderType: JSON.parse(localStorage.getItem("orderType")),
  agentType: JSON.parse(localStorage.getItem("agentType")),
  hospitalDetails: JSON.parse(localStorage.getItem("hospitalDetails")),
  requestType: JSON.parse(localStorage.getItem("requestType")),
  bloodComponent: JSON.parse(localStorage.getItem("bloodComponent")),
  bloodGroup: JSON.parse(localStorage.getItem("bloodGroup")),
  genotype: JSON.parse(localStorage.getItem("genotype")),
  orderInfo: JSON.parse(localStorage.getItem("orderInfo")),
  orderQuantity: JSON.parse(localStorage.getItem("orderQuantity")),
  paymentMethod: JSON.parse(localStorage.getItem("paymentMethod")),
  orderReason: JSON.parse(localStorage.getItem("orderReason")),
  cylinderSize: JSON.parse(localStorage.getItem("cylinderSize")),
  oxygenType: JSON.parse(localStorage.getItem("oxygenType")),
  patient: JSON.parse(localStorage.getItem("patient")),
  stockbank: JSON.parse(localStorage.getItem("stockbank")),
  products: {},
  quipProducts: {},
  placedOrder: {},
  existingOrder: {},
  boatOrder: {},
};

// Redux reducer function
const newOrderReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case SAVE_ORDER_TYPE:
      return {
        ...state,
        orderType: payload,
      };
    case SAVE_AGENT_TYPE:
      return {
        ...state,
        agentType: payload,
      };
    case SAVE_HOSPITAL_DETAILS:
      return {
        ...state,
        hospitalDetails: payload,
      };
    case SAVE_REQUEST_TYPE:
      return {
        ...state,
        requestType: payload,
      };
    case SAVE_BLOOD_COMPONENT:
      return {
        ...state,
        bloodComponent: payload,
      };
    case SAVE_BLOOD_GROUP:
      return {
        ...state,
        bloodGroup: payload,
      };
    case SAVE_GENOTYPE:
      return {
        ...state,
        genotype: payload,
      };
    case SAVE_ORDER_INFO:
      return {
        ...state,
        orderInfo: payload,
      };
    case SAVE_ORDER_QUANTITY:
      return {
        ...state,
        orderQuantity: payload,
      };
    case SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload,
      };
    case SAVE_ORDER_REASON:
      return {
        ...state,
        orderReason: payload,
      };
    case SAVE_PATIENT_NAME:
      return {
        ...state,
        patient: payload,
      };
    case SAVE_CYLINDER_SIZE:
      return {
        ...state,
        cylinderSize: payload,
      };
    case SAVE_OXYGEN_TYPE:
      return {
        ...state,
        oxygenType: payload,
      };

    case SAVE_STOCKBANK_DETAILS:
      return {
        ...state,
        stockbank: payload,
      };

    case FETCH_PRODUCTS:
      return {
        ...state,
        products: payload,
      };

    case FETCH_QUIP_PRODUCTS:
      return {
        ...state,
        quipProducts: payload,
      };
    case PLACE_ORDER:
      return {
        ...state,
        placedOrder: payload,
      };

    case EXISTING_ORDER:
      return {
        ...state,
        existingOrder: payload,
      };

    case BOAT_ORDER:
      return {
        ...state,
        boatOrder: payload,
      };

    default:
      return state;
  }
};

export default newOrderReducer;
