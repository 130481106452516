import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewOrders from "./components/orders/NewOrders";
import Customer from "./screens/customer/Customer";
import Dashboard from "./screens/dashboard";
import Layout from "./screens/dashboardLayout";
import Detail from "./screens/placeOrder/hospitalDetail/Detail";
import BloodComponent from "./screens/placeOrder/bloodComponent/BloodComponent";
import BloodGroup from "./screens/placeOrder/bloodComponent/BloodGroup";
import OrderInformation from "./screens/placeOrder/orderInformation/OrderInformation";
import PlaceOrder from "./screens/placeOrder/PlaceOrder";
import Quantity from "./screens/placeOrder/quantity/Quantity";
import Payment from "./screens/placeOrder/payment/Payment";
import Reason from "./screens/placeOrder/reason/Reason";
import OrderDetails from "./screens/placeOrder/information/OrderDetails";
import ProcessOrder from "./screens/placeOrder/processOrder/ProcessOrder";
import CompletedOrder from "./components/orders/completed/CompletedOrder";
import Track from "./screens/track/Track";
import LoginScreen from "./screens/Login/LoginScreen";
import ErrorBoundary from "./components/ErrorBoundary";

import { Provider } from "react-redux";
import store from "./redux/store";

// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtherBloodComponent from "./screens/placeOrder/bloodComponent/Others";
import OtherReason from "./screens/placeOrder/reason/OtherReason";
import CircularLoader from "./components/Loader/CircularLoader";
import CylinderSize from "./screens/placeOrder/cylinderSize/CylinderSize";
import Cylinder from "./screens/placeOrder/cylinderSize/Cylinder";
import Exchange from "./screens/placeOrder/cylinderSize/exchange/Exchange";
import OrderType from "./screens/placeOrder/cylinderSize/orderType/OrderType";
import OtherScreen from "./screens/placeOrder/other/OtherScreen";
import OrderDetail from "./screens/placeOrder/stockbankInformation/OrderDetail";
import ProductCard from "./components/InfoCard/ProductCard";
import ProductSelection from "./components/InfoCard/ProductSelection";
import QuipSelection from "./components/InfoCard/QuipSelection";
import ProcessNewOrder from "./screens/placeOrder/processOrder/ProcessNewOrder";
import ProcessNewOxygen from "./screens/placeOrder/processOrder/ProcessNewOxygen";
import ProcessNewStockbank from "./screens/placeOrder/processOrder/ProcessNewStockbank";
import Rider from "./screens/rider/Rider";
import StockProducts from "./components/orderInstruction/StockProducts";
import QuipProducts from "./components/orderInstruction/QuipProducts";
import DeclinedItem from "./screens/declinedOrders/DeclinedItem";
import ProcessDeclinedItems from "./components/orderInstruction/ProcessDeclinedItems";
// import StockItems from "./components/orderInstruction/StockItems";
import ProcessStockBank from "./screens/placeOrder/processOrder/processStockBank";
import Settings from "./screens/Login/Settings";
import Genotype from "./screens/placeOrder/bloodComponent/Genotype";
import Patient from "./screens/placeOrder/patient/Patient";
import Agent from "./screens/placeOrder/agent/Agent";
import Po from "./components/orders/po.jsx/Po";
import Podetails from "./components/orders/po.jsx/Podetails";
import Quotation from "./screens/purchaseOrder/Quotation";
import HospitalReport from "./screens/purchaseOrder/HospitalReport";
import QuotationDetail from "./screens/purchaseOrder/QuotationDetails";
import Bid from "./screens/bid/Bid";
import ViewBid from "./screens/bid/ViewBid";
import QuipCard from "./components/InfoCard/QuipCard";
import NerveUsage from "./screens/nerveusage/NerveUsage";
// import DeclineItem from "./components/orders/ova/DeclineItem";

function App() {
  return (
    <React.Fragment>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-items-center min-vh-100">
              {/* Loading Please Wait... */}
              <CircularLoader />
            </div>
          }
        >
          <Provider store={store}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
            />
            <Router>
              <Routes>
                <Route>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="new-orders" element={<NewOrders />} />
                    <Route path="stockbank/:id" element={<StockProducts />} />
                    <Route path="quip/:id" element={<QuipProducts />} />
                    {/* <Route path="stockitem/:id" element={<StockItems />} /> */}
                    <Route path="ongoing-orders" element={<CompletedOrder />} />
                    <Route path={`/bid`} element={<Bid />} />
                    <Route path={`/view-bid/:id`} element={<ViewBid />} />
                    <Route path="tickets" element={<Customer />} />
                    <Route path={`/po`} element={<Po />} />
                    <Route path={`/podetails/:id`} element={<Podetails />} />
                    <Route path={`/see-quotation`} element={<Quotation />} />
                    <Route path={`/hospital-report`} element={<HospitalReport />} />
                    <Route path={`/declined-orders`} element={<DeclinedItem />} />
                    <Route path="process-declined-item/:id" element={<ProcessDeclinedItems />} />
                    {/* <Route
                      path={`/quote/:id`}
                      element={<Quote />}
                    /> */}
                    <Route
                      path={`/quotation-detail/:id`}
                      element={<QuotationDetail />}
                    />
                    <Route path="riders" element={<Rider />} />
                    {/* <Route path="ova" element={<DeclineItem />} /> */}
                    <Route path="place-order">
                      <Route index element={<PlaceOrder />} />
                      <Route path="hospital-detail" element={<Detail />} />
                      <Route path="select-products" element={<ProductCard />} />
                      <Route path="product-selection/:hospitalId" element={<ProductSelection />} />
                      <Route path="quip-selection/:hospitalId" element={<QuipSelection />} />
                      <Route path="quip" element={<QuipCard />} />
                      <Route path="select-agent" element={<Agent />} />
                      <Route
                        path="blood-component"
                        element={<BloodComponent />}
                      />
                      <Route
                        path="other-blood-component"
                        element={<OtherBloodComponent />}
                      />
                      <Route path="size" element={<CylinderSize />} />
                      <Route path="cylinder-size" element={<Cylinder />} />
                      <Route path="cylinder-exchange" element={<Exchange />} />
                      <Route path="order-type" element={<OrderType />} />
                    </Route>
                    <Route path="blood-group" element={<BloodGroup />} />
                    <Route path="genotype" element={<Genotype />} />
                    <Route
                      path="order-information"
                      element={<OrderInformation />}
                    />
                    <Route path="quantity" element={<Quantity />} />
                    <Route path="payment" element={<Payment />} />
                    <Route path="patient" element={<Patient />} />
                    <Route path="reason" element={<Reason />} />
                    <Route path="nerve-usage" element={<NerveUsage />} />
                    <Route path="other-reason" element={<OtherReason />} />
                    <Route path="order-details" element={<OrderDetails />} />
                    <Route path="order-info" element={<OrderDetail />} />
                    <Route
                      path="process-order/:id"
                      element={<ProcessOrder />}
                    />
                    <Route
                      path="processorder/:id"
                      element={<ProcessStockBank />}
                    />
                    <Route
                      path="process-neworder"
                      element={<ProcessNewOrder />}
                    />
                    <Route
                      path="process-orders"
                      element={<ProcessNewStockbank />}
                    />
                    <Route
                      path="process-order"
                      element={<ProcessNewOxygen />}
                    />
                    <Route path="track-order/:id" element={<Track />} />
                    <Route path="not-available" element={<OtherScreen />} />
                  </Route>
                </Route>
                <Route path="/login" element={<LoginScreen />} />
              </Routes>
            </Router>
          </Provider>
        </Suspense>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
