import React, { useState } from "react";
import { MdWaterDrop } from "react-icons/md";
import { AiFillTags } from "react-icons/ai";
import { HiLocationMarker, HiOutlineViewGridAdd } from "react-icons/hi";
import { GiScubaTanks } from "react-icons/gi";
import { FaSyringe } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import style from "./Completed.module.scss";
import styled from "./Modal.module.scss";
import Button from "@mui/material/Button";
import { ConfirmationModal } from "../../Modal/ConfirmationModal";
import ChatModal from "../../Modal/ChatModal";
import { SuccessModal } from "../../Modal/SuccessModal";
import Ticket from "../../Form/Ticket";
import MessageInstruction from "../../modalInstruction/MessageInstruction";
import { constructOrderDate } from "../../../helper/DateFormatter";
import ReturnInstruction from "../../modalInstruction/ReturnInstruction";
import { useDispatch, useSelector } from "react-redux";
import {
  // closeOrder,
  completedOrders,
  // processOrder,
  trackOrder,
} from "../../../redux/orders/actions/orders.actions";
import { useNavigate } from "react-router-dom";
import {
  EditOutlined,
  IssuesCloseOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import PaymentDetails from "../../modalInstruction/PaymentDetails";
import UpdateForm from "../../modalInstruction/UpdateForm";
import { editComponent, editType } from "../../../data";
import Card from "../../paymentCard/Card";
import { delay } from "../nerve/Nerve";
import QtyModal from "./QtyModal";

const Completed = ({
  order_id,
  id,
  component,
  name,
  product,
  address,
  city,
  state,
  tym,
  order_by,
  qty,
  title,
  dispatch,
  dispatch_name,
  vendor_name,
  payment_type,
  total,
}) => {
  const [openModal, setopenModal] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  // const [successModal, setSuccessModal] = useState(false);
  // const [load, setLoad] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateType, setUpdateType] = useState(false);
  const [closeUpdateModal, setCloseUpdateModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }
  const newModal = () => {
    setOpenChatModal(false);
    setopenModal(false);
    setReturnModal(true);
  };

  const handleRowClick = (id) => {
    setIsOpen(true);
  };

  const successFunc = () => {
    setTicketModal(false);
    // setSuccessModal(true);
  };

  const badgeColor = () => {
    if (title === "blood") return "#FDE0E0";
    if (title === "oxygen") return "#E0EFFD";
    if (title === "Quip") return "#ffd4c2";
    return "#F0FDE0";
  };

  const icons = () => {
    if (title === "blood") return <MdWaterDrop size={25} color="#B70303" />;
    if (title === "oxygen") return <GiScubaTanks size={25} color="#2457C5" />;
    if (title === "Quip") return <FiSettings size={25} color="#c7460f" />;
    return <FaSyringe size={25} color="#78A55A" />;
  };

  const colors = () => {
    if (title === "blood") return "#B70303";
    if (title === "oxygen") return "#2457C5";
    if (title === "Quip") return "#c7460f";
    return "#78A55A";
  };

  const dispatcher = useDispatch();

  const [loading, setLoading] = useState(false);

  const { closed } = useSelector((state) => state.orderReducer);

  const data = {
    title: title === "blood" ? "Blood" : title,
    order_by: order_by,
    bloodtype: product,
  };

  // update function
  const updateTypeFunc = async () => {
    // dispatcher(processOrder(data));
    // setLoading(true);
    await delay(3000);
    // setLoading(false);
    setOpenChatModal(true);
    // setUpdateType(true);
  };

  const handleClose = () => {
    // setLoading(true);
    // if (title === "oxygen") {
    //   dispatcher(
    //     closeOrder({
    //       title: title === "blood" ? "Blood" : title,
    //       order_id: order_id,
    //     })
    //   );
    // } else {
    //   dispatcher(
    //     closeOrder({
    //       title:
    //         title === "blood"
    //           ? "Blood"
    //           : title === "StockBank"
    //           ? "stockbank"
    //           : title,
    //       order_id: order_id,
    //     })
    //   );
    // }

    setLoading(false);
    setPaymentModal(false);
    setCloseModal(true);
    // return;
  };

  const handleUpdate = () => {
    setUpdateModal(false);
    setCloseUpdateModal(true);
  };

  const exitUpdateModal = () => {
    setCloseUpdateModal(false);
    dispatcher(completedOrders());
  };

  const exitModal = () => {
    setCloseModal(false);
    dispatcher(completedOrders());
  };

  const exitReturnModal = () => {
    setReturnModal(false);
    dispatcher(completedOrders());
  };

  // const navigation = () => {
  //   navigate("/customer-care");
  // };

  const trackHandler = (id) => {
    dispatcher(trackOrder(id, { title: title === "blood" ? "Blood" : title }));
    navigate(`/track-order/${order_id}`);
  };

  const editHandler = (type) => {
    window.localStorage.setItem("editType", type?.label);
    setUpdateType(false);
    setUpdateModal(true);
  };

  let selectedType;

  if (window.localStorage.getItem("editType"))
    selectedType = window.localStorage.getItem("editType");

  return (
    <>
      <div>
        <div className={style.container}>
          <div className={style.innerContainer}>
            <div className={style.content}>
              <div
                className={`${style.icon}`}
                style={{ background: badgeColor() }}
              >
                {icons()}
              </div>
              <div className="d-flex flex-column">
                <p className={style.header}>Product Name</p>
                <p className={style.text}>{product}</p>
              </div>
            </div>

            <div className={style.content} onClick={handleRowClick}>
              <div className={style.icon} style={{ background: badgeColor() }}>
                <HiOutlineViewGridAdd size={25} color={colors()} />
              </div>
              <div className="d-flex flex-column">
                <p className={style.header}>QUANTITY</p>
                <p className={style.text}>{qty}</p>
              </div>
            </div>
            <div className={style.content}>
              <div className="d-flex flex-column">
                <p className={style.header}>PAYMENT TYPE</p>
                <p className={style.text}>{payment_type}</p>
              </div>
            </div>
            <div className={style.content}>
              <div className="d-flex flex-column">
                <p className={style.header}>TOTAL</p>
                <p className={style.text}>{total}</p>
              </div>
            </div>
            <div className={style.content}>
              <div className="d-flex flex-column">
                <p className={style.header}>HOSPITAL NAME</p>
                <p className={style.text}>{name}</p>
              </div>
            </div>
            <div className={`${style.content} ${style.address}`}>
              <div className={style.icon} style={{ background: badgeColor() }}>
                <HiLocationMarker size={25} color={colors()} />
              </div>
              <div className="d-flex flex-column">
                <p className={style.header}>HOSPITAL ADDRESS</p>
                <p className={style.text}>{` ${address}, ${city}, ${state}`}</p>
              </div>
            </div>
            <div className={style.content}>
              <div className="d-flex flex-column">
                <Button
                  className={style.button}
                  style={{
                    border: `2px solid ${badgeColor()}`,
                    color: colors(),
                  }}
                  onClick={() => setPaymentModal(true)}
                  disabled={loading}
                >
                  close
                </Button>
                <Button
                  className={style.button}
                  style={{
                    border: `2px solid ${badgeColor()}`,
                    color: colors(),
                  }}
                  onClick={() => setopenModal(true)}
                  disabled={loading}
                >
                  return
                </Button>
                <Button
                  className={style.button}
                  style={{
                    border: `2px solid ${badgeColor()}`,
                    color: colors(),
                  }}
                  onClick={() => setTicketModal(true)}
                  disabled={loading}
                >
                  complain
                </Button>

                <Button
                  className={style.button}
                  style={{
                    border: `2px solid ${badgeColor()}`,
                    color: colors(),
                  }}
                  onClick={() => trackHandler(order_id)}
                  disabled={loading}
                >
                  track
                </Button>
              </div>
            </div>
          </div>
          <div className={style.bott}>
            <div className={style.time}>
              Last update :{constructOrderDate(tym)}
            </div>

            <div className={style.update} onClick={updateTypeFunc}>
              {loading ? <SyncOutlined spin /> : "Chat"}
            </div>
          </div>
        </div>

        {/* Chat Modal */}
        <ChatModal
          open={openChatModal}
          handleClose={() => setOpenChatModal(false)}
          closemodal={() => setOpenChatModal(false)}
          bgcolor="background.paper"
          width="50vw"
          order_id={order_id}
          dispatch={dispatch}
          title={title}
        />

        {/* DIALOG MODAL */}
        <ConfirmationModal
          open={openModal}
          handleClose={() => setopenModal(false)}
          closemodal={() => setopenModal(false)}
          bgcolor="background.paper"
          width="50vw"
          instruction={
            <ReturnInstruction
              button2="RETURN"
              categoryBg={badgeColor()}
              headerColor={colors()}
              categoryColor={colors()}
              category={title}
              textColor={colors()}
              btnColor={colors()}
              productName={product}
              id={order_id}
              hospital={name}
              qtyDelivered={qty}
              title={title === "blood" ? "Blood" : title}
              order_by={order_by}
              agent={userDetails?.id}
              onClickYes={() => newModal()}
            />
          }
        />

        {/* Return MODAL */}
        <SuccessModal
          open={returnModal}
          onClose={() => setReturnModal(false)}
          bgcolor={colors()}
          width="50vw"
          height="35vh"
          instruction={
            <MessageInstruction
              heading={"RETURN SUCCESSFUL"}
              content={" Your order has been successfully returned"}
              button="COMPLETE"
              onClickBtn={exitReturnModal}
              color={badgeColor()}
            />
          }
        />

        {/* Close MODAL */}

        {/* ticket MODAL */}
        <ConfirmationModal
          open={ticketModal}
          handleClose={() => setTicketModal(false)}
          closemodal={() => setTicketModal(false)}
          bgcolor=" rgba(255, 255, 255, 0.92)"
          width="75vw"
          instruction={
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-between mt-3 mb-3">
                <p className={styled.breadcumb} style={{ color: colors() }}>
                  <AiFillTags size={25} className="me-3" />
                  <span>NEW TICKET</span>{" "}
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Ticket
                  hospital={name}
                  order_id={order_id}
                  id={title === "blood" ? order_id : id}
                  title={title === "blood" ? "Blood" : title}
                  order_by={order_by}
                  agent={userDetails?.id}
                  onClickNo={() => setTicketModal(false)}
                  onClickYes={() => successFunc()}
                />
              </div>
            </div>
          }
        />

        {/* create MODAL */}
        {/* <SuccessModal
          open={successModal}
          onClose={() => setSuccessModal(false)}
          bgcolor={colors()}
          width="60vw"
          height="55vh"
          instruction={
            <MessageInstruction
              heading={"SUCCESSFUL!!"}
              content={` A ticket has been created for Order ID - ${order_id}`}
              button="COMPLETE"
              onClickBtn={navigation}
              color={badgeColor()}
            />
          }
        /> */}

        {/* close details MODAL */}
        <ConfirmationModal
          open={paymentModal}
          handleClose={() => setPaymentModal(false)}
          closemodal={() => setPaymentModal(false)}
          bgcolor=" rgba(255, 255, 255, 0.92)"
          width="55vw"
          instruction={
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-between mt-3 mb-3">
                <p className={styled.breadcumb} style={{ color: colors() }}>
                  <IssuesCloseOutlined
                    style={{ fontSize: "35px" }}
                    className="me-3"
                  />
                  <span>CLOSE ORDER</span>{" "}
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <PaymentDetails
                  name={name}
                  qty={qty}
                  product={product}
                  title={title}
                  order_id={order_id}
                  // id={title === "blood" ? order_id : id}
                  // title={title === "blood" ? "Blood" : title}
                  order_by={order_by}
                  agent={userDetails?.id}
                  onClickNo={() => setPaymentModal(false)}
                  onClickYes={() => handleClose()}
                />
              </div>
            </div>
          }
        />
        {/* update Modal */}
        <ConfirmationModal
          open={updateType}
          handleClose={() => setUpdateType(false)}
          closemodal={() => setUpdateType(false)}
          bgcolor=" rgba(255, 255, 255, 0.92)"
          width="50%"
          instruction={
            <div>
              <h3 className={style.header2} style={{ color: colors() }}>
                What would you like to update?
              </h3>
              {title === "blood" ? (
                <div className={style.cards}>
                  {editComponent.map((type) => (
                    <Card
                      {...type}
                      key={type.id}
                      onClick={() => editHandler(type)}
                    />
                  ))}
                </div>
              ) : (
                <div className={style.cards}>
                  {editType.map((type) => (
                    <Card
                      {...type}
                      key={type.id}
                      onClick={() => editHandler(type)}
                    />
                  ))}
                </div>
              )}
            </div>
            // </div>
          }
        />

        {/* UPDATE MODAL */}
        <ConfirmationModal
          open={updateModal}
          handleClose={() => setUpdateModal(false)}
          closemodal={() => setUpdateModal(false)}
          bgcolor=" rgba(255, 255, 255, 0.92)"
          width="40vw"
          instruction={
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-between mt-3 mb-3">
                <p className={styled.breadcumb} style={{ color: colors() }}>
                  <EditOutlined style={{ fontSize: "35px" }} className="me-3" />
                  <span>
                    {selectedType === "DISPATCH"
                      ? "UPDATE DISPATCH RIDER"
                      : selectedType === "PAYMENT"
                      ? "UPDATE PAYMENT MODE"
                      : selectedType === "COMPONENT"
                      ? "UPDATE COMPONENT"
                      : selectedType === "QUANTITY"
                      ? "UPDATE QUANTITY"
                      : "UPDATE VENDOR"}
                  </span>{" "}
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <UpdateForm
                  name={name}
                  qty={qty}
                  product={product}
                  title={title}
                  bloodComponent={component}
                  order_id={order_id}
                  dispatch={dispatch_name}
                  vendor={vendor_name}
                  b_type={product}
                  payment={payment_type}
                  order_by={order_by}
                  agent={userDetails?.id}
                  onClickNo={() => setUpdateModal(false)}
                  onClickYes={() => handleUpdate()}
                />
              </div>
            </div>
          }
        />

        {/* Update success MODAL */}
        <SuccessModal
          open={closeUpdateModal}
          onClose={() => setCloseUpdateModal(false)}
          bgcolor={colors()}
          width="50vw"
          height="35vh"
          instruction={
            <MessageInstruction
              heading={"ORDER UPDATED SUCCESSFULLY!!"}
              // content={` It took this order ${closed?.time} Hours to be closed`}
              button="EXIT"
              onClickBtn={exitUpdateModal}
              color={badgeColor()}
            />
          }
        />
      </div>

      {isOpen && (
        <QtyModal
          setIsOpen={setIsOpen}
          id={order_id}
          title={title}
        />
      )}
    </>
  );
};

export default Completed;
