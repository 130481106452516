import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Podetailmodal from "./PoModal.jsx";
import axiosClient from "../../../service/axios";
import styles from "./podetail.module.scss";
import pdf from "../../../assets/images/pdf.png";
import { SyncOutlined } from "@ant-design/icons";
import CircularLoader from "../../Loader/CircularLoader";
import {
  somethingWentWrongToast,
  orderCreateSuccessToast,
  addInventoryToast,
} from "../../NotificationToast/index.js";
import { IoChevronBackCircle } from "react-icons/io5";
import Modal from "../../../screens/UploadPage/Modal.jsx";

const Podetails = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedPackSize, setSelectedPackSize] = useState("");
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [qttyUnit, setQttyPerUnit] = useState();
  const [newList, setNewList] = useState([]);
  const [product, setProduct] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goBack = () => {
    navigate(`/po`);
  };

  useEffect(() => {
    try {
      setLoading(true);
      const fetchData = async () => {
        const response = await axiosClient.get(
          `/lite/v2/api/admin/po/list/${id}`
        );
        setData(response.data.data);
        setLoading(false);
        setProduct(response.data.product);
      };
      fetchData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [id]);

  const handleAdd = () => {
    if (
      selectedItem &&
      selectedBrand &&
      selectedPackSize &&
      qttyUnit
    ) {
      const newItem = {
        item: selectedItem,
        brand: selectedBrand !== "None" ? selectedBrand : "None",
        packSize: selectedPackSize !== "None" ? selectedPackSize : "None",
        quantity: qttyUnit,
      };

      setProduct([...product, newItem]);
      setNewList([...newList, newItem]);

      setSelectedItem("");
      setSelectedBrand("");
      setSelectedPackSize("");
      setQttyPerUnit("");
    } else {
      addInventoryToast();
    }
  };

  const handleDelete = (index) => {
    const updatedItemsList = [...newList];
    updatedItemsList.splice(index, 1);
    setNewList(updatedItemsList);
  };

  const handleSubmit = async () => {
    setShowSpinner(true);
    try {
      if (newList.length === 0) {
        somethingWentWrongToast();
        return;
      }
      const response = await axiosClient.post(
        `/lite/v2/api/admin/po/set/${id}`,
        {
          selectedItems: newList, id,
        }
      );

      if (response.status === 200) {
        orderCreateSuccessToast();
        setShowSpinner(false);

        setTimeout(() => {
          navigate(`/po`);
        }, 5000);
      } else {
        somethingWentWrongToast();
      }

      // Clear the newList array
      setNewList([]);
    } catch (error) {
      console.error("Error submitting data:", error);
      somethingWentWrongToast();
    }
  };

  const isPDF = data.po?.toLowerCase().endsWith(".pdf");

  const imageLink = () => {
    window.open(data.po, "_blank");
  };

 
   const getBrands = (itemName) => {
    const item = product.find((item) => item.name === itemName);
    const brands = item ? item?.brand?.split(", ") : [];
    return ["None", ...brands.filter(Boolean)];
  };

  const getPackSizes = (itemName) => {
    const item = product.find((item) => item.name === itemName);
    const packSizes = item ? [item.packsize, item.cartonsize] : [];
    return ["None", ...packSizes.filter(Boolean)];
  };

  return (
    <>
      {/* {modal && (
        <Podetailmodal item={selectedItem} id={id} setModal={setModal} />
      )} */}
      <div className={styles.purchaseOrderText} onClick={goBack}>
        <IoChevronBackCircle />
      </div>

      <div className={styles.uploadCon}>
        <div className={styles.imagecontainer}>
          <div className={styles.boxShadow}>
            {/* <div className={styles.imagecontainer}> */}
            {isPDF ? (
              // <div onClick={() => openModal(data.po)}>
              <img src={pdf} alt="po_image" className={styles.imagedetails} />
            ) : (
              // </div>
              // <div onClick={() => openModal(data.po)}>
              <img
                src={data.po}
                alt="po_image"
                className={styles.imagedetails}
              />
              // </div>
            )}
            {/* </div> */}
          </div>

          <button onClick={imageLink} className={styles.imgBtn}>
            VIEW IMAGE
          </button>
        </div>

        <div className={styles.updateCon}>
          <div style={{
            height:'25rem'
          }} className={styles.productContainer}>
            <div className={styles.header}>
              <h1>PURCHASE ORDER</h1>
            </div>
            <div className={styles.productList}>
              <div className={styles.select}>
                <select
                  value={selectedItem}
                  onChange={(e) => {
                    setSelectedItem(e.target.value);
                    setSelectedBrand("");
                    setSelectedPackSize("");
                  }}
                >
                  <option value="">Select Item</option>
                  {product?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

         

              {selectedItem && (
                <>
                  <div className={styles.select}>
                    <select
                      value={selectedBrand}
                      onChange={(e) => setSelectedBrand(e.target.value)}
                    >
                      <option value="">Select Brand</option>
                      {getBrands(selectedItem)?.map((brand) => (
                        <option key={brand} value={brand}>
                          {brand}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.select}>
                    <select
                      value={selectedPackSize}
                      onChange={(e) => setSelectedPackSize(e.target.value)}
                    >
                      <option value="">Select Pack Size</option>
                      {getPackSizes(selectedItem)?.map((packSize) => (
                        <option key={packSize} value={packSize}>
                          {packSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}

              <div className={styles.priceQtty}>
                {/* <div className={styles.priceInput}>
                  <input
                    type="text"
                    placeholder="Price per unit"
                    value={pricePerUnit}
                    onChange={(e) => setPricePerUnit(e.target.value)}
                  />
                </div> */}

                <div className={styles.quantityControl}>
                  <div className={styles.plusMinus}>
                    <input
                      type="text"
                      placeholder="Quantity"
                      value={qttyUnit}
                      onChange={(e) => setQttyPerUnit(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.addButton} onClick={handleAdd}>
              Add
            </div>
          </div>

          {/* Scrollable container to display added items */}
          <div className={styles.submit}>
            <div className={styles.scrollableContainer} hover>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Pack Size</th>
                    {/* <th>Price per unit</th> */}
                    <th>Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {newList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.item}</td>
                      <td>{item.brand}</td>
                      <td>{item.packSize}</td>
                      {/* <td>{item.cost}</td> */}
                      <td>{item.quantity}</td>
                      <td>
                        <button
                          className={styles.button}
                          onClick={() => handleDelete(index)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={styles.btnCon} onClick={handleSubmit}>
              <div className={styles.buttonText}>{showSpinner ? <SyncOutlined spin /> : "Update"}</div>
            </div>
          </div>
        </div>

        {/* Modal */}
        {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
          <img src={modalImage} alt="po_image" className={styles.modalImage} />
        </Modal> */}
      </div>
    </>
  );
};

export default Podetails;
