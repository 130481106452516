import React, { useEffect, useState } from "react";
import Details from "../../../components/InfoCard/Details";
import {
  BsGearFill,
  BsFillGrid3X3GapFill,
  BsCalculator,
  BsInboxesFill,
} from "react-icons/bs";
import { IoIosPeople, IoMdPricetags } from "react-icons/io";
import {
  FaRuler,
  FaRegMoneyBillAlt,
  FaMoneyBillWave,
  FaUserInjured,
  FaUserMd,
  FaHospital,
} from "react-icons/fa";
import {
  RiHandHeartFill,
  RiBillFill,
  RiTestTubeFill,
  RiScales2Fill,
  RiFirstAidKitFill,
} from "react-icons/ri";
import { ImDroplet } from "react-icons/im";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { MdOutlineDateRange, MdAccessTime, MdBloodtype } from "react-icons/md";
import { GiScubaTanks, GiFirstAidKit } from "react-icons/gi";
import style from "./Information.module.scss";
import Naira from "react-naira";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SyncOutlined } from "@ant-design/icons";

import dateFormat from "../../../helper/DateFormatter";
import { processOrder } from "../../../redux/orders/actions/orders.actions";
import { Col, Row } from "react-bootstrap";
import ContactFinanceModal from "./FinanceModal";

const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    agentType,
    orderType,
    hospitalDetails,
    genotype,
    bloodComponent,
    oxygenType,
    cylinderSize,
    bloodGroup,
    orderInfo,
    orderQuantity,
    paymentMethod,
    orderReason,
    patient,
  } = useSelector((state) => state.newOrderReducer);
  console.log("orderInfo", orderInfo);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  let orderPrice;
  if (localStorage.getItem("orderPrice")) {
    orderPrice = JSON.parse(localStorage.getItem("orderPrice"));
  }

  let hmo;
  if (localStorage.getItem("hmo")) {
    hmo = JSON.parse(localStorage.getItem("hmo"));
  }

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return;
  };

  const icons = () => {
    if (orderType?.cardText === "BLOOD")
      return <ImDroplet size={20} color={colors()} />;
    if (orderType?.cardText === "OXYGEN")
      return <GiScubaTanks size={20} color={colors()} />;
    if (orderType?.cardText === "STOCKBANK")
      return <BsInboxesFill size={20} color={colors()} />;
    return <GiFirstAidKit size={20} color={colors()} />;
  };

  useEffect(() => {
    // Check if the component has been loaded before
    const hasLoadedBefore = localStorage.getItem("orderDetailsLoaded");

    if (!hasLoadedBefore) {
      // If not, reload the page once
      localStorage.setItem("orderDetailsLoaded", "true");
      window.location.reload();
    }

    // Clean up the flag on component unmount (optional)
    return () => {
      localStorage.removeItem("orderDetailsLoaded");
    };
  }, []);

  const data = {
    // id: index + 1,
    agentType: agentType,
    title: orderType?.cardText,
    cylinderSize: cylinderSize?.id,
    oxygenType: oxygenType,
    blood: bloodComponent?.bloodComponent,
    b_type: bloodGroup?.bloodGroup,
    genotype: genotype?.genotype,
    requestType: hospitalDetails?.requestType,
    schedule_date: hospitalDetails?.date,
    schedule_tym: hospitalDetails?.time,
    usage: orderReason?.reason,
    Cross_matching: orderInfo?.cross,
    pcvTests: orderInfo?.test,
    temperature: orderInfo?.temp,
    genotyping: orderInfo?.genotype,
    order_amt: orderQuantity?.id,
    payment_type: paymentMethod?.label,
    unitPrice: "",
    extraCharges: "",
    totalPrice: "",
    order_by: hospitalDetails?.hospital?.value,
    agent: userDetails?.id,
    patient: patient?.patient,
    hmo: hmo?.hospital,
    doctor: hmo?.doctor,
  };

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const handleCloseModal = async () => {
    setShowModal(false);
    localStorage.setItem("newOrder", JSON.stringify(data));
    dispatch(
      processOrder({
        title:
          orderType?.cardText === "BLOOD"
            ? "Blood"
            : orderType?.cardText === "OXYGEN"
            ? "Oxygen"
            : orderType?.cardText,
        order_by: hospitalDetails?.hospital?.value,
        bloodtype: bloodGroup?.bloodGroup,
      })
    );
    setLoading(true);
    await delay(4000);
    setLoading(false);

    if (orderType?.cardText === "BLOOD") {
      navigate("/process-neworder");
    } else if (orderType?.cardText === "OXYGEN") {
      navigate("/process-order");
    } else if (localStorage.getItem("userToken")) {
      navigate("/place-order");
    } else {
      navigate("/");
    }
  };

  const processFunc = async () => {
    // Check if pricing is not available
    if (
      (!orderPrice?.price || orderPrice.price === "N/A") &&
      (!orderInfo?.cross || orderInfo.cross === "No") &&
      (!orderInfo?.genotype || orderInfo.genotype === "No")
    ) {
      setShowModal(true);
      return;
    }

    handleCloseModal();
  };

  let bloodTotal = orderPrice?.price * orderQuantity?.id || 0;
  let oxygenTotal = orderPrice?.price * orderQuantity?.id || 0;

  let genoCross = +orderPrice?.crossmatch + +orderPrice?.genotype || 0;

  let bloodExtra = +orderPrice?.price * +orderQuantity?.id + +genoCross || 0;
  let bloodCross =
    +orderPrice?.price * +orderQuantity?.id + +orderPrice?.crossmatch || 0;
  let bloodGeno =
    +orderPrice?.price * +orderQuantity?.id + +orderPrice?.genotype || 0;

  const priceCheck = localStorage.getItem("orderPrice");

  const item = JSON.parse(priceCheck);
  return (
    <>
      <div style={{ width: "97%" }} className="m-3">
        <Row className={style.header} style={{ background: colors() }}>
          <Col md={1}>
            <IoIosArrowDropleftCircle
              size={50}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              color="white"
            />
          </Col>
          <Col md={8} className="text-center">
            CONFIRM ORDER
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: colors(),
              }}
            >
              {item.Nerve}
            </p>
          </Col>
        </Row>
        <Details icon={icons()} label={"PRODUCT"} text={orderType?.cardText} />

        {orderType?.cardText === "OXYGEN" && (
          <>
            <Details
              icon={<RiScales2Fill size={20} color={colors()} />}
              label={"CYLINDER SIZE"}
              text={cylinderSize?.size?.toUpperCase()}
            />
            <Details
              icon={<BsFillGrid3X3GapFill size={20} color={colors()} />}
              label={"ORDER TYPE"}
              text={oxygenType?.toUpperCase()}
            />
          </>
        )}

        {orderType?.cardText === "BLOOD" && (
          <Details
            icon={<BsGearFill size={20} color={colors()} />}
            label={"COMPONENT"}
            text={bloodComponent?.bloodComponent?.toUpperCase()}
          />
        )}

        <Details
          icon={<FaUserInjured size={20} color={colors()} />}
          label={"PATIENT'S NAME"}
          text={patient?.patient ? patient?.patient?.toUpperCase() : "-"}
        />

        {orderType?.cardText === "BLOOD" && (
          <Details
            icon={<IoIosPeople size={20} color={colors()} />}
            label={"BLOOD GROUP"}
            text={
              bloodGroup?.bloodGroup === "UNSURE"
                ? "UNKNOWN"
                : bloodGroup?.bloodGroup?.toUpperCase()
            }
          />
        )}

        {orderType?.cardText === "BLOOD" && (
          <Details
            icon={<MdBloodtype size={20} color={colors()} />}
            label={"GENOTYPE"}
            text={
              genotype?.genotype === "UNSURE"
                ? "UNKNOWN"
                : genotype?.genotype?.toUpperCase()
            }
          />
        )}

        <div className="mt-4 mb-4">
          {orderType?.cardText !== "OXYGEN" && (
            <Details
              icon={<BsFillGrid3X3GapFill size={20} color={colors()} />}
              label={"REQUEST TYPE"}
              text={hospitalDetails?.requestType?.toUpperCase()}
            />
          )}

          {hospitalDetails?.requestType === "Scheduled" && (
            <>
              <Details
                icon={<MdOutlineDateRange size={20} color={colors()} />}
                label={"SCHEDULED DATE"}
                text={dateFormat.DateYearMonthDay(hospitalDetails?.date)}
              />
              <Details
                icon={<MdAccessTime size={20} color={colors()} />}
                label={"SCHEDULED TIME"}
                text={dateFormat.TimeOnly(hospitalDetails?.time)}
              />
            </>
          )}
        </div>

        <div className="mb-4">
          <Details
            icon={<RiHandHeartFill size={20} color={colors()} />}
            label={"PURPOSE"}
            text={orderReason?.reason?.toUpperCase()}
          />

          {orderType?.cardText === "BLOOD" && (
            <>
              <Details
                icon={<RiTestTubeFill size={20} color={colors()} />}
                label={"CROSS MATCHING"}
                text={orderInfo?.cross?.toUpperCase()}
              />
              <Details
                icon={<MdBloodtype size={20} color={colors()} />}
                label={"GENOTYPING"}
                text={orderInfo?.genotype?.toUpperCase()}
              />
              <Details
                icon={<RiFirstAidKitFill size={20} color={colors()} />}
                label={"MALARIA / PCV TESTS"}
                text={orderInfo?.test?.toUpperCase()}
              />
              <Details
                icon={<FaRuler size={20} color={colors()} />}
                label={"TEMPERATURE STRIPS"}
                text={orderInfo?.temp?.toUpperCase()}
              />
            </>
          )}
        </div>

        <Details
          icon={<BsCalculator size={20} color={colors()} />}
          label={"QUANTITY"}
          text={orderQuantity?.id}
        />
        <Details
          icon={<FaRegMoneyBillAlt size={20} color={colors()} />}
          label={"PAYMENT METHOD"}
          text={paymentMethod?.label}
        />
        {paymentMethod?.label === "HMO" && (
          <>
            <Details
              icon={<FaHospital size={20} color={colors()} />}
              label={"HOSPITAL NAME"}
              text={hmo?.hospital}
            />
            <Details
              icon={<FaUserMd size={20} color={colors()} />}
              label={"DOCTOR's NAME"}
              text={hmo?.doctor}
            />
          </>
        )}

        {orderType?.cardText === "BLOOD" && (
          <div className="mt-4">
            <Details
              icon={<FaMoneyBillWave size={20} color={colors()} />}
              label={"UNIT PRICE"}
              text={
                orderPrice?.price === "N/A" || !orderPrice?.price
                  ? "0 Ksh"
                  : `${orderPrice?.price} Ksh`
              }
            />

            <Details
              icon={<IoMdPricetags size={20} color={colors()} />}
              label={"EXTRA CHARGES"}
              text={
                orderInfo?.cross === "Yes" && orderInfo?.genotype === "No"
                  ? `${!orderPrice ? 0 : orderPrice?.crossmatch} Ksh`
                  : orderInfo?.genotype === "Yes" && orderInfo?.cross === "No"
                  ? `${!orderPrice ? 0 : orderPrice?.genotype} Ksh`
                  : orderInfo?.cross === "Yes" && orderInfo?.genotype === "Yes"
                  ? `${!orderPrice ? 0 : genoCross} Ksh`
                  : "0 Ksh"
              }
            />
          </div>
        )}
        {/* <Details
        icon={<RiCouponFill size={20} color="green" />}
        label={"ENTER PROMO CODE"}
        text={""}
      /> */}

        {orderType?.cardText === "OXYGEN" && (
         <Details
              icon={<FaMoneyBillWave size={20} color={colors()} />}
              label={"UNIT PRICE"}
              text={
                orderPrice?.price === "N/A" || !orderPrice?.price
                  ? "0 Ksh"
                  : `${orderPrice?.price} Ksh`
              }
            />
        )}

        {orderType?.cardText === "BLOOD" ? (
          <Details
            icon={<RiBillFill size={20} color={colors()} />}
            // label={"TOTAL"}
            text={
                orderInfo?.cross === "Yes" && orderInfo?.genotype === "No"
                  ? `${!orderPrice ? 0 : orderPrice?.crossmatch} Ksh`
                  : orderInfo?.genotype === "Yes" && orderInfo?.cross === "No"
                  ? `${!orderPrice ? 0 : orderPrice?.genotype} Ksh`
                  : orderInfo?.cross === "Yes" && orderInfo?.genotype === "Yes"
                  ? `${!orderPrice ? 0 : genoCross} Ksh`
                  : "0 Ksh"
              }
          />
        ) : (
          <Details
            icon={<RiBillFill size={20} color={colors()} />}
            label={"TOTAL"}
            text={
              orderPrice?.price === "N/A" || !orderPrice?.price ? (
                "0"
              ) : (
                `${oxygenTotal} Ksh`
              )
            }
          />
        )}
        {/* <Details
        icon={<AiFillInfoCircle size={20} color={colors()} />}
        label={
          orderType?.cardText === "BLOOD"
            ? "PLEASE NOTE THAT LIFEBANK DOES NOT SELL BLOOD, WE ONLY CHARGE FOR SERVICES PROVIDED. TAP TO VIEW MORE"
            : "HAVE SOME QUESTIONS? TAP TO LEARN"
        }
      /> */}
        <button
          className={style.btn}
          onClick={processFunc}
          style={{ background: colors() }}
        >
          {loading ? <SyncOutlined spin /> : "PLACE ORDER"}
        </button>
      </div>

      <ContactFinanceModal
        show={showModal}
        handleClose={handleCloseModal}
        close={() => setShowModal(false)}
      />
    </>
  );
};

export default OrderDetails;
