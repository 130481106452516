import React, { useState, useEffect } from "react";
import DeclinedOrders from "./DeclinedOrders";
import axiosClient from "../../service/axios";
import { SyncOutlined } from "@ant-design/icons";

const DeclinedItem = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const declineHandler = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        "/lite/v2/api/admin/ops/declines/orders"
      );

      const responseData = response?.data?.data;
      console.log(responseData, "response");
      setData(responseData);
      setLoading(false);
    } catch (error) {
      console.log("error is decline");
    }
  };

  useEffect(() => {
    declineHandler();
  }, []);

  return (
    <div>
      {data.length > 0 ? (
        data.map((item, index) => (
          <DeclinedOrders
            key={index}
            // agent={item.agent}
            order_id={item.order_id}
            channel={item.channel}
            // vendor_id={item.vendor_id}
            // discount={item.discount}
            // dispatch={item.dispatch}
            order_by={item.order_by}
            // order_id={item.order_id}
            // order_state={item.order_state}
            // payment={item.payment}
            // requester={item.requester}
            // decline_by={item.decline_by}
            status={item.status}
            // total={item.total}
            // bgColor={item.bgColor}
            color={item.color}
            // icon={item.icon}
            product={item.product}
            // reason={item.reason}
            fone={item.fone}
            // personnel_name={item.personnel_name}
            // order_amt={item.order_amt}
            // qty={item.qty}
            name={item.name}
            // address={item.address}
            // city={item.city}
            // state={item.state}
            // order_source={item.order_source}
            tym={item.tym}
            // size={item.size}
            // constructOrderDate={item.constructOrderDate}
          />
        ))
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          {loading ? <SyncOutlined spin /> : "Items Not Available"}
        </div>
      )}
    </div>
  );
};

export default DeclinedItem;
