import React, { useState } from "react";
import { MdWaterDrop } from "react-icons/md";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { AiFillMedicineBox } from "react-icons/ai";
import { GiScubaTanks } from "react-icons/gi";
import { FaSyringe } from "react-icons/fa";
import { SyncOutlined } from "@ant-design/icons";
// import { PiBroadcastDuotone } from "react-icons/pi";

import style from "./DeclinedOrders.module.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../components/Modal/ConfirmationModal";
import StockbankInstruction from "../../components/orderInstruction/stockbankInstruction";
import BloodInstruction from "../../components/orderInstruction/BloodInstruction";
import OxygenInstruction from "../../components/orderInstruction/OxygenInstruction";
import { constructOrderDate } from "../../helper/DateFormatter";
import { processOrder } from "../../redux/orders/actions/orders.actions";
import { useDispatch } from "react-redux";

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const DeclinedOrders = ({
  order_id,
  id,
  order_by,
  order_state,
  personnel_name,
  address,
  fone,
  size,
  b_type,
  reason,
  order_amt,
  payment,
  usage_info,
  agent,
  channel,
  payment_type,
  decline_by,
  discount,
  requester,
  status,
  total,
  tym,
  vendor_id,
  product,
  title,
  city,
  name,
  qty,
  state,
  //   bgColor,
  //   color,
  //   icon,
}) => {
  const [openModal, setopenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log("idddddd",order_id)import { IoCheckmarkCircle } from "react-icons/io5";

  const bgColor = () => {
    if (product === "Blood") return "#E0EFFD";
    if (product === "StockBank") return "#E0EFFD";
    if (product === "Quip") return "#ffd4c2";
    return "#F0FDE0";
  };

  const icon = () => {
    if (product === "Blood")
      return <MdWaterDrop size={25} color="#B70303" />;
    if (product === "StockBank")
      return <GiScubaTanks size={25} color="#78A55A" />;
    if (product === "Quip")
      return <AiFillMedicineBox size={25} color="#c7460f" />;
    return <FaSyringe size={25} color="#F0FDE0" />;
  };

  const color = () => {
    if (product === "Blood") return "#B70303";
    if (product === "StockBank") return "#78A55A";
    if (product === "Quip") return "#c7460f";
    return "#F0FDE0";
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const data = {
    title: title === "blood" ? "Blood" : title,
    order_by: order_by,
    bloodtype: b_type,
  };

  const processHandler = async () => {
    dispatch(processOrder(data));
    setLoading(true);
    await delay(3000);
    setLoading(false);
    if (title === "oxygen") {
      navigate(`/process-order/${id}`);
    } else {
      navigate(`/process-order/${order_id}`);
    }
  };

  const modalPerformance = () => {
    // if (title === "StockBank") {
    navigate(`/process-declined-item/${order_id}`, { state: { product } });
    // } else {
    //   setopenModal(true);
    // }
  };

  return (
    <div className={style.container}>
      {/* <div> */}
      <div onClick={modalPerformance}>
        <div className={style.innerContainer}>
          <div className={style.content}>
            <div className={style.icon} style={{ background: bgColor() }}>
              {icon()}
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>Product Name</p>

              {product}
            </div>
          </div>

          <div className={style.content}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <MdOutlineTipsAndUpdates size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>Date</p>
              <p className={style.text}>{constructOrderDate(tym)}</p>
            </div>
          </div>
          <div className={style.content}>
            <div className="d-flex flex-column">
              <p className={style.header}>HOSPITAL NAME</p>
              <p className={style.text}>{name}</p>
            </div>
          </div>
          {/* <div className={style.content}>
            <div className="d-flex flex-column">
              <p className={style.header}>REASON FOR DECLINATION</p>
              <p className={style.text}>{"N/A"}</p>
            </div>
          </div> */}
          {/* <div className={`${style.content} ${style.address}`}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <HiLocationMarker size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>HOSPITAL ADDRESS</p>
              <p className={style.text}>{"N/A"}</p>
              <p className={style.text}>{` ${city}, ${state}`}</p>
            </div>
          </div> */}

          {/* <div className={`${style.content} ${style.address}`}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <HiLocationMarker size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>SOURCE</p>
              <p className={style.text}></p>
            </div>
          </div> */}

          <div className={style.content}>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className={style.button}
                style={{ border: `2px solid ${bgColor()}`, color: color() }}
                onClick={modalPerformance}
              >
                Send To Vendor Success
              </Button>
            </div>
          </div>
        </div>
        <div className={style.time}>Last update :{tym}</div>
      </div>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        bgcolor="background.paper"
        width="80vw"
        handleClose={() => setopenModal(false)}
        closemodal={() => setopenModal(false)}
        instruction={
          title === "StockBank" ? (
            <StockbankInstruction
              status={title}
              button2={loading ? <SyncOutlined spin /> : `PROCESS ORDER`}
              orderId={order_id}
              category={title}
              headerColor={color()}
              categoryBg={bgColor()}
              categoryColor={color()}
              btnColor={color()}
            />
          ) : title === "blood" ? (
            <BloodInstruction
              button2={loading ? <SyncOutlined spin /> : `PROCESS ORDER`}
              orderId={`LB${order_id}`}
              product="Blood"
              bloodGroup={b_type}
              quantity={order_amt}
              vendor={name}
              reason={usage_info}
              request={personnel_name}
              address={address}
              phone={fone}
              payment={payment_type}
              time={constructOrderDate(tym)}
              category={title}
              headerColor={color()}
              categoryBg={bgColor()}
              categoryColor={color()}
              btnColor={color()}
              onClickYes={processHandler}
            />
          ) : (
            <OxygenInstruction
              status={title}
              button2={loading ? <SyncOutlined spin /> : `PROCESS ORDER`}
              orderId={id}
              product={size}
              quantity={qty}
              payment={payment_type}
              hospital={personnel_name}
              address={`${address}, ${city}, ${state}`}
              category={title}
              time={constructOrderDate(tym)}
              headerColor={color()}
              phone={fone}
              categoryBg={bgColor()}
              categoryColor={color()}
              btnColor={color()}
              onClickYes={processHandler}
            />
          )
        }
      />
      {/* </div> */}
    </div>
  );
};

export default DeclinedOrders;
