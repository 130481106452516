import React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import styles from "./Reason.module.scss";

function Sensitive({ sensitive, setSensitive, value }) {
  const handleModalClose = () => {
    setSensitive(false);
  };

  return (
    <Dialog open={sensitive} onClose={handleModalClose}>
      <DialogContent style={{ paddingBottom: "0" }}>
        <div className={styles.title}> {value ? value : "No sensitive information"}</div>
      </DialogContent>

      <DialogActions>
        <div
          style={{
            cursor: "pointer",
            color: "#fff",
            backgroundColor: "#B70303",
            padding: "10px",
            borderRadius: "3px",
            border: "1px solid #DFE1E6",
            fontSize: "14px",
            textAlign: "center",
          }}
          onClick={handleModalClose}
        >
          Close
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default Sensitive;
