/* 
@example

    export const ACTION_NAME = "ACTION_NAME"
*/

/*

  @Authentications/User
*/

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";

/*

 @Admin

*/

// @Notifications
export const LOAD_NOTIFICATION_COUNT = "LOAD_NOTIFICATION_COUNT";

// - dashboard count
export const LOAD_DASHBOARD_COUNT = "LOAD_DASHBOARD_COUNT";

// - @LabX Orders
export const LOAD_LABX_ORDERS = "LOAD_LABX_ORDERS";

// - @ussd Orders
export const LOAD_USSD_ORDERS = "LOAD_USSD_ORDERS";

// - @Call Orders
export const LOAD_CALL_ORDERS = "LOAD_CALL_ORDERS";

// - @Boat Orders
export const LOAD_BOAT_ORDERS = "LOAD_BOAT_ORDERS";

// - @Ongoing Orders
export const LOAD_ONGOING_ORDERS = "LOAD_ONGOING_ORDERS";

// - @Overdue Orders
export const LOAD_OVERDUE_ORDERS = "LOAD_OVERDUE_ORDERS";

// - @completed Orders
export const LOAD_COMPLETED_ORDERS = "LOAD_COMPLETED_ORDERS";

// - @Hospital Lists
export const LOAD_HOSPITAL_LIST = "LOAD_HOSPITAL_LIST";
export const LOAD_HOSPITAL = "LOAD_HOSPITAL";

// - @Dispatch List
export const LOAD_DISPATCH_LIST = "LOAD_DISPATCH_LIST";

// - @Lab List
export const LOAD_LAB_LIST = "LOAD_LAB_LIST";

// - @Oxygen list
export const LOAD_OXYGEN_LIST = "LOAD_OXYGEN_LIST";

// @NEW ORDER DETAILS
export const SAVE_ORDER_TYPE = "SAVE_ORDER_TYPE";

export const SAVE_AGENT_TYPE = "SAVE_AGENT_TYPE";

export const SAVE_HOSPITAL_DETAILS = "SAVE_HOSPITAL_DETAILS";

export const SAVE_REQUEST_TYPE = "SAVE_REQUEST_TYPE";

export const SAVE_BLOOD_COMPONENT = "SAVE_BLOOD_COMPONENT";

export const SAVE_BLOOD_GROUP = "SAVE_BLOOD_GROUP";

export const SAVE_GENOTYPE = "SAVE_GENOTYPE";

export const SAVE_ORDER_INFO = "SAVE_ORDER_INFO";

export const SAVE_ORDER_QUANTITY = "SAVE_ORDER_QUANTITY";

export const SAVE_PAYMENT_METHOD = "SAVE_PAYMENT_METHOD";

export const SAVE_ORDER_REASON = "SAVE_ORDER_REASON";

export const SAVE_CYLINDER_SIZE = "SAVE_CYLINDER_SIZE";

export const SAVE_OXYGEN_TYPE = "SAVE_OXYGEN_TYPE";

export const SAVE_PATIENT_NAME = "SAVE_PATIENT_NAME";

export const SAVE_STOCKBANK_DETAILS = "SAVE_STOCKBANK_DETAILS";

export const CLOSE_ORDER = "CLOSE_ORDER";

export const RETURN_ORDER = "RETURN_ORDER";

export const PROCESS_ORDER = "PROCESS_ORDER";

export const TICKET = "TICKET";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";

export const FETCH_QUIP_PRODUCTS = "FETCH_QUIP_PRODUCTS";

export const TRACK_ORDER = "TRACK_ORDER";

export const ORDER_PRICE = "ORDER_PRICE";

export const PLACE_ORDER = "PLACE_ORDER";

export const LOAD_RIDERS = "LOAD_RIDERS";

export const EXISTING_ORDER = "EXISTING_ORDER";

export const BOAT_ORDER = "BOAT_ORDER";

export const NOTIFICATIONS = "NOTIFICATIONS";

export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION";

export const STOCKBANK_PRODUCTS = "STOCKBANK_PRODUCTS";

export const QUIP_PRODUCTS = "QUIP_PRODUCTS";

export const UPDATE_ORDER = "UPDATE_ORDER";

export const GET_CHAT = "GET_CHAT";

export const POST_CHAT = "POST_CHAT";

export const SHOW_ALL_PO = "SHOW_ALL_PO";

export const GET_ALL_QUOTATION = "GET_ALL_QUOTATION"

export const SINGLE_QUOTATION =  "SINGLE_QUOTATION"

export const BID_QUOTATION =  "BID_QUOTATION"