import {
  somethingWentWrongToast,
  orderCreateSuccessToast,
  complainToast,
} from "../../../components/NotificationToast";
import {
  LOAD_LABX_ORDERS,
  LOAD_CALL_ORDERS,
  LOAD_USSD_ORDERS,
  LOAD_BOAT_ORDERS,
  LOAD_ONGOING_ORDERS,
  LOAD_OVERDUE_ORDERS,
  LOAD_COMPLETED_ORDERS,
  LOAD_HOSPITAL_LIST,
  LOAD_HOSPITAL,
  LOAD_DISPATCH_LIST,
  LOAD_LAB_LIST,
  CLOSE_ORDER,
  PROCESS_ORDER,
  LOAD_OXYGEN_LIST,
  TRACK_ORDER,
  ORDER_PRICE,
  LOAD_RIDERS,
  STOCKBANK_PRODUCTS,
  QUIP_PRODUCTS,
  NOTIFICATIONS,
  UPDATE_ORDER,
  VIEW_NOTIFICATION,
} from "../../types";
import OrderService from "../services/orders.service";

//@labX orders
export const labXOrders = (id) => async (dispatch) => {
  try {
    const response = await OrderService.fetchLabXOrders(id);

    dispatch({
      type: LOAD_LABX_ORDERS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@ussd orders
export const ussdOrders = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchUssdOrders();

    dispatch({
      type: LOAD_USSD_ORDERS,
      payload: response.data,
    });

    console.log("response", response.data); //response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@call orders
export const callOrders = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchCallOrders();

    dispatch({
      type: LOAD_CALL_ORDERS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@boat orders
export const boatOrders = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchBoatOrders();

    dispatch({
      type: LOAD_BOAT_ORDERS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@Ongoing orders
export const ongoingOrders = (id) => async (dispatch) => {
  try {
    const response = await OrderService.fetchOngoingOrders(id);

    dispatch({
      type: LOAD_ONGOING_ORDERS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@Overdue orders
export const overdueOrders = (id) => async (dispatch) => {
  try {
    const response = await OrderService.fetchOverdueOrders(id);

    dispatch({
      type: LOAD_OVERDUE_ORDERS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@completed orders
export const completedOrders = (id) => async (dispatch) => {
  try {
    const response = await OrderService.fetchCompletedOrders(id);

    dispatch({
      type: LOAD_COMPLETED_ORDERS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
    console.log("err::", error);
  }
};

//@Hospital List
export const hospitalList = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchHospital();

    dispatch({
      type: LOAD_HOSPITAL_LIST,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

export const hospitalListStock = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchHospitalStock();

    dispatch({
      type: LOAD_HOSPITAL,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@Dispatch List
export const dispatchList = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchDispatch();

    dispatch({
      type: LOAD_DISPATCH_LIST,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@Lab List
export const labList = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchLabs();

    dispatch({
      type: LOAD_LAB_LIST,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@Rider List
export const ridersList = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchRiders();

    dispatch({
      type: LOAD_RIDERS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@oxygen List
export const oxygenList = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchOxygen();

    dispatch({
      type: LOAD_OXYGEN_LIST,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

// Create new PO
export const addNewOrder = (details, actions) => async (dispatch) => {
  try {
    const response = await OrderService.createOrder(details);

    if (
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      somethingWentWrongToast();
      actions.setSubmitting(false);
      return;
    }

    if (response.status === 200) {
      orderCreateSuccessToast();
      actions.setSubmitting(false);
      actions.resetForm();
      return response;
    }

    somethingWentWrongToast();
    actions.setSubmitting(false);

    return response;
  } catch (error) {
    actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

// Close Order
export const closeOrder = (details, actions) => async (dispatch) => {
  try {
    const response = await OrderService.closeOrder(details);
    if (response.status === 200) {
      dispatch({
        type: CLOSE_ORDER,
        payload: response?.data,
      });
    } else {
      somethingWentWrongToast();
    }

    return response;
  } catch (error) {
    actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

// @return order
export const returnOrder = (details, actions) => async (dispatch) => {
  try {
    const response = await OrderService.returnOrder(details);

    // something went wrong
    if (response?.response?.status === 400) {
      actions.setSubmitting(false);
      somethingWentWrongToast();
      return;
    }

    // returned successfull
    if (response.status === 200) {
      actions.setSubmitting(false);
      actions.resetForm();
    }
    return response;
  } catch (error) {
    actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

// @complain order
export const complainOrder = (details, actions) => async (dispatch) => {
  try {
    const response = await OrderService.complainOrder(details);

    // something went wrong
    if (response?.response?.status === 400) {
      somethingWentWrongToast();
      return;
    }

    // complained successfull
    if (response.status === 200) {
      complainToast();
      // actions.resetForm();
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

// Close Order
export const processOrder = (details, actions) => async (dispatch) => {
  try {
    const response = await OrderService.processOrder(details);

    if (response.status === 200) {
      // console.log("response::", response?.data);
      dispatch({
        type: PROCESS_ORDER,
        payload: response?.data,
      });
      localStorage.setItem("precheck", JSON.stringify(response?.data));

      actions.setSubmitting(false);
      actions.resetForm();
    }

    return response;
  } catch (error) {
    // actions.setSubmitting(false);
    return error;
  }
};

// track Order
export const trackOrder = (details, id) => async (dispatch) => {
  try {
    const response = await OrderService.trackOrder(id, details);
    if (response.status === 200) {
      dispatch({
        type: TRACK_ORDER,
        payload: response?.data,
      });
      localStorage.setItem("trackOrder", JSON.stringify(response?.data));
    }

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

// Order Price
export const orderPrice = (details) => async (dispatch) => {
  try {
    const response = await OrderService.orderPrice(details);
    if (response.status === 200) {
      dispatch({
        type: ORDER_PRICE,
        payload: response?.data,
      });
      localStorage.setItem("orderPrice", JSON.stringify(response?.data));
    }

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

//@stockbank products orders
export const fetchStockbank = (id) => async (dispatch) => {
  try {
    const response = await OrderService.fetchStockbank(id);

    dispatch({
      type: STOCKBANK_PRODUCTS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
    console.log("err::", error);
  }
};

export const fetchQuip = (id) => async (dispatch) => {
  try {
    const response = await OrderService.fetchQuip(id);

    dispatch({
      type: QUIP_PRODUCTS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
    console.log("err::", error);
  }
};

//@Notification List
export const notificationsList = () => async (dispatch) => {
  try {
    const response = await OrderService.fetchNotifications();

    dispatch({
      type: NOTIFICATIONS,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@Notification List
export const viewNotification = (id, channel) => async (dispatch) => {
  try {
    const response = await OrderService.viewNotification(id, channel);
    if (response.status === 200) {
      dispatch({
        type: VIEW_NOTIFICATION,
        payload: response?.data,
      });

    }

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//  @Update Order

export const updateOrderDetails = (details, actions) => async (dispatch) => {
  try {
    const response = await OrderService.updateOrder(details);

    if (response.status === 200) {
      dispatch({
        type: UPDATE_ORDER,
        payload: response?.data,
      });

      actions.resetForm();
    }

    return response;
  } catch (error) {
    return error;
  }
};
