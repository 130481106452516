// import React, { useEffect, useState } from "react";
// import { useLocation, useParams, useNavigate } from "react-router-dom";
// import style from "./ProductCard.module.scss";
// import { FaHandPaper } from "react-icons/fa";
// import { RiErrorWarningFill } from "react-icons/ri";
// import { IoIosArrowDropleftCircle } from "react-icons/io";
// import Checkbox from "@mui/material/Checkbox";
// import Naira from "react-naira";
// import {
//   fetchProducts,
//   saveStockbank,
// } from "../../redux/new-order/actions/new-orders.actions";
// import { useDispatch, useSelector } from "react-redux";
// import { ConfirmationModal } from "../Modal/ConfirmationModal";
// import { hospitalList } from "../../redux/orders/actions/orders.actions";
// import { SelectField } from "../Form/SelectField";
// import { Formik } from "formik";
// import { Col, Row } from "react-bootstrap";
// import CircularLoader from "../Loader/CircularLoader";

// const ProductSelection = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [openModal, setopenModal] = useState(false);
//   const [lowOrder, setLowOrder] = useState(false);
//   const location = useLocation();
//   const { hospitalId } = useParams();
//   const { pro } = location.state || {};

//   let userDetails;
//   if (localStorage.getItem("userToken")) {
//     userDetails = JSON.parse(localStorage.getItem("userToken"));
//   }

//   if (!userDetails) {
//     navigate("/");
//   }

//   const [loading, setLoading] = useState(true);

//   const [checked, setChecked] = useState([]);

//   const handleToggle = (value) => () => {
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//       newChecked.push(value);
//     } else {
//       newChecked.splice(currentIndex, 1);
//     }

//     setChecked(newChecked);
//   };

//   let total = checked
//     .reduce((acc, item) => acc + item.qty * item.cost, 0)
//     .toFixed(2);

//   //increase counter
//   const increase = (card) => {
//     const newChecked = [...checked];

//     const index = newChecked.indexOf(card);
//     newChecked[index].qty++;

//     setChecked(newChecked);
//   };

//   //decrease counter
//   const decrease = (card) => {
//     const newChecked = [...checked];

//     const index = newChecked.indexOf(card);
//     // newChecked[index].qty -= 1;
//     newChecked[index].qty > 1
//       ? (newChecked[index].qty -= 1)
//       : (newChecked[index].qty -= 0);

//     setChecked(newChecked);
//   };

//   const { orderType, products } = useSelector((state) => state.newOrderReducer);

//   const productss = products?.products;

//   const colors = () => {
//     if (orderType?.cardText === "BLOOD") return "#B70303";
//     if (orderType?.cardText === "OXYGEN") return "#0779F7";
//     if (orderType?.cardText === "STOCKBANK") return "#066304";
//     if (orderType?.cardText === "QUIP") return "#DA621B";
//   };

//   const [hospitalDropDown, setHospitalDropDown] = useState();

//   const handleNavigate = () => {
//     const values = {
//       checked,
//       pro,
//       hospitalId,
//     };
//     localStorage.setItem("formValues", JSON.stringify(values));
//     dispatch(saveStockbank(values));
//     navigate("/payment");
//   };

//   useEffect(() => {
//     dispatch(fetchProducts())
//       .then((res) => {
//         setLoading(false);
//       })
//       .catch((error) => {
//         setLoading(false);
//       });
//     dispatch(hospitalList()).then((response) => {
//       let options = response.data
//         .sort((a, b) => a.label - b.label)
//         .map((hosp) => ({
//           label: `${hosp.Name} (${hosp.State}-${hosp.City})`,
//           value: hosp.Hospital_ID,
//         }));
//       setHospitalDropDown(options);
//       // console.log("name:", response.data[0].Name);
//     });
//   }, [dispatch]);

//   return (
//     <div className={style.container}>
//       <Row>
//         <Col md={1}>
//           <IoIosArrowDropleftCircle
//             size={50}
//             color={colors()}
//             style={{ cursor: "pointer" }}
//             onClick={() => navigate(-1)}
//           />
//         </Col>
//         <Col md={10}>
//           <h3 className={style.header} style={{ color: colors() }}>
//             hospital detail
//           </h3>
//           {/* <h4 className={style.requestHeader1} style={{ color: colors() }}>
//             select hospital
//           </h4> */}
//           <Formik
//             initialValues={{
//               hospital: "",
//               checked: "",
//             }}
//             validate={(values) => {
//               const errors = {};
//               if (!values.hospital) {
//                 errors.hospital = "This field is mandatory";
//               }
//               return errors;
//             }}
//             // onSubmit={(values, actions) => {
//             //   values.hospital = values.hospital;
//             //   values.checked = checked;
//             //   console.log("values", values);
//             //   localStorage.setItem("formValues", JSON.stringify(values));

//             //   if (checked?.length < 1) {
//             //     setLowOrder(true);
//             //   } else if (total > 25300) {
//             //     // dispatch(saveStockbank(values));
//             //     navigate("/payment");
//             //     return;
//             //   } else {
//             //     setopenModal(true);
//             //   }
//             // }}
//           >
//             {({
//               handleChange,
//               handleSubmit,
//               isSubmitting,
//               values,
//               resetForm,
//               setFieldValue,
//               handleBlur,
//               errors,
//               touched,
//             }) => (
//               <form>
//                 {/* <div className={`${style.formInput} shadow-none`}>
//                   <SelectField
//                     className={style.input}
//                     name="hospital"
//                     options={hospitalDropDown}
//                     onChange={setFieldValue}
//                     value={values?.hospital}
//                     placeholder="Find a hospital here"
//                     onBlur={handleBlur}
//                   />
//                   {errors.hospital && touched.hospital ? (
//                     <p className="text-danger mt-1 mx-2">{errors.hospital}</p>
//                   ) : null}
//                 </div> */}

//                 <h4 className={style.requestHeader} style={{ color: colors() }}>
//                   select products
//                 </h4>

//                 <div>
//                   {loading && (
//                     <div className="d-flex justify-content-center align-items-center">
//                       <CircularLoader />
//                     </div>
//                   )}
//                   <div
//                     className={style.allProduct}
//                     style={{ maxHeight: "50vh", overflow: "scroll" }}
//                   >
//                     {productss?.map((product) => {
//                       // const labelId = `${product.id}`;
//                       return (
//                         <div className={style.eachProduct} key={product.id}>
//                           <div className={style.top}>
//                             <div className={style.each}>
//                               <div className={style.icon}>
//                                 <FaHandPaper size={30} color="white" />
//                               </div>
//                               <div className={style.title}>
//                                 <p className={style.name}>{product.name}</p>
//                                 <p className={style.desc}>
//                                   {product.description}
//                                 </p>
//                               </div>
//                             </div>
//                             <Checkbox
//                               edge="end"
//                               onChange={handleToggle(product)}
//                               checked={checked.indexOf(product) !== -1}
//                               // inputProps={labelId}
//                             />
//                           </div>

//                           <div className={style.each}>
//                             {checked ? (
//                               <div>
//                                 <div className={style.control}>
//                                   <div
//                                     className={style.controlBtn}
//                                     onClick={() => decrease(product)}
//                                   >
//                                     -
//                                   </div>

//                                   <div className={style.qty}>{product.qty}</div>
//                                   <div
//                                     className={style.controlBtn}
//                                     onClick={() => increase(product)}
//                                   >
//                                     +
//                                   </div>
//                                 </div>
//                               </div>
//                             ) : (
//                               <></>
//                             )}
//                             <div className={style.line}></div>
//                             <p className={style.price}>
//                               {/* KSh{" "} */}
//                               {new Intl.NumberFormat("en-KE", {
//                                 style: "currency",
//                                 currency: "KES",
//                               })
//                                 .format(
//                                   product.qty === "0"
//                                     ? product.cost * 1
//                                     : product.cost * product.qty
//                                 )
//                                 .replace("KES", "")
//                                 .trim()}
//                             </p>
//                           </div>
//                         </div>
//                       );
//                     })}
//                   </div>
//                   <div
//                     type="submit"
//                     name="Scheduled"
//                     onClick={handleNavigate}
//                     className={style.next}
//                   >
//                     {"NEXT"}
//                   </div>
//                   {/* DIALOG MODAL */}
//                   <ConfirmationModal
//                     open={openModal}
//                     handleClose={() => setopenModal(false)}
//                     bgcolor="background.paper"
//                     width="40vw"
//                     closemodal={() => setopenModal(false)}
//                     instruction={
//                       <div className={style.instructionContainer}>
//                         <RiErrorWarningFill size={80} color="#B70303" />
//                         <div className={style.total}>
//                           Cart Total -{" "}
//                           KSh {new Intl.NumberFormat('en-KE').format(total)}
//                         </div>
//                         <p className={style.warning}>
//                           Order total must be at least{" "}
//                           <span className={style.naira}>
//                           KSh 25300
//                           </span>
//                           . Please select some additional items.
//                         </p>

//                         <button
//                           className={style.okay}
//                           onClick={() => setopenModal(false)}
//                         >
//                           Got it
//                         </button>
//                       </div>
//                     }
//                   />

//                   {/* DIALOG MODAL */}
//                   <ConfirmationModal
//                     open={lowOrder}
//                     handleClose={() => setLowOrder(false)}
//                     bgcolor="background.paper"
//                     width="40vw"
//                     closemodal={() => setLowOrder(false)}
//                     instruction={
//                       <div className={style.instructionContainer}>
//                         <RiErrorWarningFill size={80} color="#B70303" />

//                         <p className={style.warning}>
//                           Please select some products
//                         </p>

//                         <button
//                           className={style.okay}
//                           onClick={() => setLowOrder(false)}
//                         >
//                           Got it
//                         </button>
//                       </div>
//                     }
//                   />
//                 </div>
//               </form>
//             )}
//           </Formik>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default ProductSelection;

import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import style from "./ProductCard.module.scss";
import { FaHandPaper } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import Checkbox from "@mui/material/Checkbox";
import Naira from "react-naira";
import {
  fetchProducts,
  saveStockbank,
} from "../../redux/new-order/actions/new-orders.actions";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationModal } from "../Modal/ConfirmationModal";
import { hospitalList } from "../../redux/orders/actions/orders.actions";
import { SelectField } from "../Form/SelectField";
import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import CircularLoader from "../Loader/CircularLoader";

const ProductSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setopenModal] = useState(false);
  const [lowOrder, setLowOrder] = useState(false);
  const location = useLocation();
  const { hospitalId } = useParams();
  const { pro } = location.state || {};

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const [loading, setLoading] = useState(true);

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  let total = checked
    .reduce((acc, item) => acc + item.qty * item.cost, 0)
    .toFixed(2);

  //increase counter
  const increase = (card) => {
    const newChecked = [...checked];

    const index = newChecked.indexOf(card);
    newChecked[index].qty++;

    setChecked(newChecked);
  };

  //decrease counter
  const decrease = (card) => {
    const newChecked = [...checked];

    const index = newChecked.indexOf(card);
    // newChecked[index].qty -= 1;
    newChecked[index].qty > 1
      ? (newChecked[index].qty -= 1)
      : (newChecked[index].qty -= 0);

    setChecked(newChecked);
  };

  const { orderType, products } = useSelector((state) => state.newOrderReducer);

  const productss = products?.products;

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    if (orderType?.cardText === "QUIP") return "#DA621B";
  };

  const [hospitalDropDown, setHospitalDropDown] = useState();

  const handleNavigate = () => {
    const values = {
      checked,
      pro,
      hospitalId,
    };
    localStorage.setItem("formValues", JSON.stringify(values));
    dispatch(saveStockbank(values));
    navigate("/payment");
  };

  useEffect(() => {
    dispatch(fetchProducts())
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    dispatch(hospitalList()).then((response) => {
      let options = response.data
        .sort((a, b) => a.label - b.label)
        .map((hosp) => ({
          label: `${hosp.Name} (${hosp.State}-${hosp.City})`,
          value: hosp.Hospital_ID,
        }));
      setHospitalDropDown(options);
      // console.log("name:", response.data[0].Name);
    });
  }, [dispatch]);

  return (
    <div className={style.container}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color={colors()}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            hospital detail
          </h3>
          {/* <h4 className={style.requestHeader1} style={{ color: colors() }}>
            select hospital
          </h4> */}
          <Formik
            initialValues={{
              hospital: "",
              checked: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.hospital) {
                errors.hospital = "This field is mandatory";
              }
              return errors;
            }}
            // onSubmit={(values, actions) => {
            //   values.hospital = values.hospital;
            //   values.checked = checked;
            //   console.log("values", values);
            //   localStorage.setItem("formValues", JSON.stringify(values));

            //   if (checked?.length < 1) {
            //     setLowOrder(true);
            //   } else if (total > 25300) {
            //     // dispatch(saveStockbank(values));
            //     navigate("/payment");
            //     return;
            //   } else {
            //     setopenModal(true);
            //   }
            // }}
          >
            {({
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              resetForm,
              setFieldValue,
              handleBlur,
              errors,
              touched,
            }) => (
              <form>
                {/* <div className={`${style.formInput} shadow-none`}>
                  <SelectField
                    className={style.input}
                    name="hospital"
                    options={hospitalDropDown}
                    onChange={setFieldValue}
                    value={values?.hospital}
                    placeholder="Find a hospital here"
                    onBlur={handleBlur}
                  />
                  {errors.hospital && touched.hospital ? (
                    <p className="text-danger mt-1 mx-2">{errors.hospital}</p>
                  ) : null}
                </div> */}

                <h4 className={style.requestHeader} style={{ color: colors() }}>
                  select products
                </h4>

                <div>
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center">
                      <CircularLoader />
                    </div>
                  )}
                  <div
                    className={style.allProduct}
                    style={{ maxHeight: "50vh", overflow: "scroll" }}
                  >
                    {productss?.map((product) => {
                      // const labelId = `${product.id}`;
                      return (
                        <div className={style.eachProduct} key={product.id}>
                          <div className={style.top}>
                            <div className={style.each}>
                              <div className={style.icon}>
                                <FaHandPaper size={30} color="white" />
                              </div>
                              <div className={style.title}>
                                <p className={style.name}>{product.name}</p>
                                <p className={style.desc}>
                                  {product.description}
                                </p>
                              </div>
                            </div>
                            <Checkbox
                              edge="end"
                              onChange={handleToggle(product)}
                              checked={checked.indexOf(product) !== -1}
                              // inputProps={labelId}
                            />
                          </div>

                          <div className={style.each}>
                            <div>
                              <div className={style.control}>
                                <input
                                  type="number"
                                  placeholder=""
                                  value={product.qty || ""}
                                  onChange={(e) => {
                                    const newQty =
                                      parseInt(e.target.value) || ""; // Allow empty input
                                    const newChecked = [...checked];
                                    const index = newChecked.indexOf(product);
                                    if (index !== -1) {
                                      newChecked[index].qty = newQty;
                                      setChecked(newChecked);
                                    }
                                  }}
                                  className={style.qtyInput}
                                />
                              </div>
                            </div>
                            <div className={style.line}></div>
                            <p className={style.price}>
                              {new Intl.NumberFormat("en-KE", {
                                style: "currency",
                                currency: "KES",
                              })
                                .format(
                                  product.qty > 0
                                    ? product.cost * product.qty
                                    : product.cost
                                )
                                .replace("KES", "")
                                .trim()}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    type="submit"
                    name="Scheduled"
                    onClick={handleNavigate}
                    className={style.next}
                  >
                    {"NEXT"}
                  </div>
                  {/* DIALOG MODAL */}
                  <ConfirmationModal
                    open={openModal}
                    handleClose={() => setopenModal(false)}
                    bgcolor="background.paper"
                    width="40vw"
                    closemodal={() => setopenModal(false)}
                    instruction={
                      <div className={style.instructionContainer}>
                        <RiErrorWarningFill size={80} color="#B70303" />
                        <div className={style.total}>
                          Cart Total - KSh{" "}
                          {new Intl.NumberFormat("en-KE").format(total)}
                        </div>
                        <p className={style.warning}>
                          Order total must be at least{" "}
                          <span className={style.naira}>KSh 25300</span>. Please
                          select some additional items.
                        </p>

                        <button
                          className={style.okay}
                          onClick={() => setopenModal(false)}
                        >
                          Got it
                        </button>
                      </div>
                    }
                  />

                  {/* DIALOG MODAL */}
                  <ConfirmationModal
                    open={lowOrder}
                    handleClose={() => setLowOrder(false)}
                    bgcolor="background.paper"
                    width="40vw"
                    closemodal={() => setLowOrder(false)}
                    instruction={
                      <div className={style.instructionContainer}>
                        <RiErrorWarningFill size={80} color="#B70303" />

                        <p className={style.warning}>
                          Please select some products
                        </p>

                        <button
                          className={style.okay}
                          onClick={() => setLowOrder(false)}
                        >
                          Got it
                        </button>
                      </div>
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default ProductSelection;
