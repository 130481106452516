import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuip,
  processOrder,
} from "../../redux/orders/actions/orders.actions";
import styled from "./Instruction.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { constructOrderDate } from "../../helper/DateFormatter";
import { SyncOutlined } from "@ant-design/icons";

const QuipProducts = ({
  name,
  address,
  tym,
}) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { labxOrders, quipProducts } = useSelector(
    (state) => state.orderReducer
  );

  // console.log("stockbankProducts", stockbankProducts)

  let nerve = labxOrders?.nerve;

  console.log("nerve",nerve)
 

  useEffect(() => {
    dispatch(fetchQuip(id));
  }, [dispatch, id]);

  const getOrderFromID = () => {
    let order;

    let match = nerve?.find((o) => o.order_id === id);
    if (match) {
      order = match;
    }

    return order;
  };

  let detail = getOrderFromID();

  const bgColor = () => {
    if (detail?.title === "blood") return "#FDE0E0";
    if (detail?.title === "oxygen") return "#E0EFFD";
    if (detail?.title === "Quip") return "#ffd4c2";
    return "#F0FDE0";
  };

  const color = () => {
    if (detail?.title === "blood") return "#B70303";
    if (detail?.title === "oxygen") return "#2457C5";
    if (detail?.title === "Quip") return "#c7460f";
    return "#78A55A";
  };

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const processHandler = async () => {
    dispatch(
      processOrder({
        title: "oxygen",
        order_by: detail?.order_by,
        order_id: detail?.order_id,
      })
    );
    setLoading(true);
    await delay(3000);
    setLoading(false);
    //   localStorage.setItem("stockDetails", JSON.stringify())
    navigate(`/process-order/${id}`);
  };

  return (
    <div className="mt-3 mb-5">
      <div className={` d-flex flex-column justify-content-center`}>
        <div className="d-flex justify-content-between mt-3 mb-3">
          <p className={styled.header} style={{ color: color() }}>
            ORDER DETAILS{" | "}
            <span>{`SBO ${id}`}</span>
          </p>
          <p
            className={styled.name}
            style={{
              background: bgColor(),
              color: color(),
              textTransform: "uppercase",
            }}
          >
            {detail?.title}
          </p>
        </div>
        <div style={{ maxHeight: "65vh", overflowY: "scroll" }}>
          <div className={styled.modalContainer2}>
            <p className={styled.title}>item(s)</p>
            <p className={styled.title}>QUANTITY</p>
            <p className={styled.title}>BRAND</p>
          </div>

          <div className={styled.stockContainer}>
            {quipProducts?.map((p, index) => (
              <div key={index} className={styled.stock}>
                <p className={styled.title}>{p.products_name}</p>
                <div className={styled.line}></div>
                <p className={styled.title}>{p.quantity}</p>
                <div className={styled.line}></div>
                <p className={styled.title}>{p.brand ? p.brand : "N/A"}</p>
              </div>
            ))}
          </div>
          <div className={`${styled.modalContainer1} mt-4`}>
            <p className={styled.title}>total product count</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{quipProducts.length}</p>
          </div>
          <div className={styled.modalContainer1}>
            <p className={styled.title}>hospital name</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{detail?.name}</p>
          </div>
          <div className={styled.modalContainer1}>
            <p className={styled.title}>hospital address</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{detail?.address}</p>
          </div>
          {/* <div className={styled.modalContainer1}>
          <p className={styled.title}>hospital phone number</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{detail?.phone}</p>
        </div> */}

          <div className={styled.modalContainer1}>
            <p className={styled.title}>order time</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{constructOrderDate(detail?.tym)}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className={` shadow-none ${styled.actionBtn} ${styled.reject}`}
            style={{ background: color() }}
            onClick={processHandler}
          >
            {loading ? <SyncOutlined spin /> : `PROCESS ORDER`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuipProducts;