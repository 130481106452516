import axiosClient from "../../../service/axios";
import {
  EXISTING_URL,
  NEW_ORDER_URL,
  PRODUCTS_URL,
  QUIP_URL,
  BOAT_URL,
} from "./new-orders.endpoints";

/*
@create New Order

*/
const createNewOrder = async (details) => {
  try {
    return await axiosClient.post(NEW_ORDER_URL, details);
  } catch (error) {
    return error;
  }
};

const processExistingOrder = async (details) => {
  try {
    return await axiosClient.post(EXISTING_URL, details);
  } catch (error) {
    return error;
  }
};

const processBoatOrder = async (details) => {
  try {
    return await axiosClient.post(BOAT_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@Fetch products
*/
const fetchProducts = async () => {
  try {
    return await axiosClient.get(PRODUCTS_URL);
  } catch (error) {
    return error;
  }
};

const fetchQuipProducts = async () => {
  try {
    return await axiosClient.get(QUIP_URL);
  } catch (error) {
    return error;
  }
};

const NewOrderService = {
  createNewOrder,
  fetchProducts,
  fetchQuipProducts,
  processExistingOrder,
  processBoatOrder,
};

export default NewOrderService;
