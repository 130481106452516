import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./Finance.css";

const Warning = ({ show, handleClose, close }) => (
  <Modal show={show} onHide={close} dialogClassName="modal-dialog">
    <Modal.Header>
      <Modal.Title className="modal-title">Contact Finance</Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-body">
      <p>
        Pricing is not available for this product. Please contact the Finance
        Team for assistance.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={close}>
        I understand
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Warning;