import React, { useState } from "react";
import styled from "./Instruction.module.scss";
import axiosClient from "../../service/axios";
import { useSelector } from "react-redux";

const MessageInstruction = ({
  heading,
  content,
  hide,
  button,
  onClickBtn,
  color,
}) => {
  const { orderType } = useSelector((state) => state.newOrderReducer);
  const inputStyle = {
    width: "400px",
    height: "50px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
    color: "#333",
  };

  const hospitalData = localStorage.getItem("newOrder");
  const orderByData = JSON.parse(hospitalData);
  const order_by = orderByData?.order_by;

  const [message, setMessage] = useState();
  // console.log("here", hide);

  const data = {
    message: message,
    order_by: order_by,
  };
  const handleSubmit = async () => {
    onClickBtn();
    // console.log("rsponse", response);
    // console.log("data", data);

    if (hide) {
      onClickBtn();
    } else {
      const response = await axiosClient.post(
        "/lite/v2/api/v3/nerve/usages/question",
        data
      );

      onClickBtn();
    }
  };

  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className={styled.return}>{heading}</p>
        <p className={styled.note}>{content}</p>
        <div className={styled.line2} style={{ background: color }}></div>
        {!hide && (
          <div
            style={{
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <p className={styled.note}>Why are you not using nerve?</p>
            <input
              style={inputStyle}
              type="text"
              placeholder="Enter reason"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        )}
        <div onClick={handleSubmit} className={styled.btn2}>
          {button}
        </div>
      </div>
    </div>
  );
};

export default MessageInstruction;
