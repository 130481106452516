import React, { useEffect, useState } from "react";
import { MdLogout, MdSettings, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { HiShoppingCart } from "react-icons/hi";
import { BiListCheck, BiDonateHeart } from "react-icons/bi";
import {
  RiDashboardFill,
  RiListCheck,
  RiCustomerService2Fill,
  RiEBike2Fill,
} from "react-icons/ri";
import { LiaClipboardListSolid } from "react-icons/lia";
import { TbReportSearch } from "react-icons/tb";
import { GoTriangleLeft, GoTriangleRight } from "react-icons/go";
import { GrTransaction } from "react-icons/gr";
import { GiCancel } from "react-icons/gi";
import liteBig from "../../assets/images/big.png";
import liteSmall from "../../assets/images/small.png";
import avatar from "./image/avatar.png";
import style from "./Sidenav.module.scss";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import IdleTimer from "../../helper/idleTimer";
import { logOut } from "../../redux/user/actions/user.actions";

const Sidenav = () => {
  const [currentLink, setCurrentLink] = useState();
  const [sideNav, setSideNav] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      setCurrentLink(1);
    } else if (pathname === "/new-orders") {
      setCurrentLink(2);
    } else if (pathname === "/ongoing-orders") {
      setCurrentLink(3);
    } else if (pathname === "/tickets") {
      setCurrentLink(4);
    } else if (pathname === "/place-order") {
      setCurrentLink(5);
    } else if (pathname === "/riders") {
      setCurrentLink(6);
    }else if (pathname === "/see-quotation") {
      setCurrentLink(7);
    } else if (pathname === "/po") {
      setCurrentLink(8);
    } else if (pathname === "/bid") {
      setCurrentLink(9)
    }
    else if (pathname === "/hospital-report") {
      setCurrentLink(10)
    }else if (pathname === "/declined-orders") {
      setCurrentLink(11)
    }
  }, [pathname]);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 1800, //expire after 30mins
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (isTimeout) {
      // dispatch(logOut());
      // localStorage.removeItem("_expiredTime");
      localStorage.removeItem("newOrder");
      localStorage.removeItem("precheck");
      localStorage.removeItem("cylinderOption");
      localStorage.removeItem("trackOrder");
      localStorage.removeItem("orderType");
      localStorage.removeItem("genotype");
      localStorage.removeItem("userToken");
    }
  }, [isTimeout]);

  const handleMouseOver = () => {
    setSideNav(true);
  };

  const handleMouseOut = () => {
    setSideNav(false);
  };

  return (
    <>
      {sideNav && (
        <div
          className={style.flexContainer1}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className={style.top}>
            <div>
              <div className={style.logo}>
                <img className='lite me-3 ms-2' src={liteBig} alt='lifebank' />
                <GoTriangleLeft
                  size={25}
                  color='white'
                  style={{ cursor: "pointer" }}
                  onClick={() => setSideNav(!sideNav)}
                />
              </div>

              <div
                className={currentLink === 1 ? style.active : style.menu}
                onClick={() => setCurrentLink(1)}
              >
                <Link className={style.link} to='/'>
                  <RiDashboardFill size={28} className={style.icon} />

                  <p> Dashboard</p>
                </Link>
              </div>

              <div
                className={currentLink === 2 ? style.active : style.menu}
                onClick={() => setCurrentLink(2)}
              >
                <Link className={style.link} to='/new-orders'>
                  <HiShoppingCart size={28} className={style.icon} />

                  <p>New Orders</p>
                </Link>
              </div>

              <div
                className={currentLink === 3 ? style.active : style.menu}
                onClick={() => setCurrentLink(3)}
              >
                <Link className={style.link} to='/ongoing-orders'>
                  <BiListCheck size={28} className={style.icon} />

                  <p> Ongoing Orders</p>
                </Link>
              </div>

              <div
                className={currentLink === 4 ? style.active : style.menu}
                onClick={() => setCurrentLink(4)}
              >
                <Link className={style.link} to='/tickets'>
                  <RiCustomerService2Fill size={28} className={style.icon} />

                  <p> Tickets</p>
                </Link>
              </div>

              <div
                className={currentLink === 5 ? style.active : style.menu}
                onClick={() => setCurrentLink(5)}
              >
                <Link className={style.link} to='/place-order'>
                  <RiListCheck size={28} className={style.icon} />

                  <p> Place Orders</p>
                </Link>
              </div>

              <div
                className={currentLink === 6 ? style.active : style.menu}
                onClick={() => setCurrentLink(6)}
              >
                <Link className={style.link} to='/riders'>
                  <RiEBike2Fill size={28} className={style.icon} />

                  <p> Dispatch</p>
                </Link>
              </div>
              <div
                className={currentLink === 7 ? style.active : style.menu}
                onClick={() => setCurrentLink(7)}
              >
                <Link className={style.link} to='/see-quotation'>
                  <LiaClipboardListSolid size={28} className={style.icon} />

                  <p> See Quotation</p>
                </Link>
              </div>

              <div
                className={currentLink === 8 ? style.active : style.menu}
                onClick={() => setCurrentLink(8)}
              >
                <Link className={style.link} to='/po'>
                  <GrTransaction size={28} className={style.icon} />

                  <p> Purchase Order</p>
                </Link>
              </div>

              <div
                className={currentLink === 9 ? style.active : style.menu}
                onClick={() => setCurrentLink(9)}
              >
                <Link className={style.link} to='/bid'>
                  <BiDonateHeart size={28} className={style.icon} />

                  <p> Bid</p>
                </Link>
              </div>

              <div
                className={currentLink === 10 ? style.active : style.menu}
                onClick={() => setCurrentLink(10)}
              >
                <Link className={style.link} to='/hospital-report'>
                  <TbReportSearch size={28} className={style.icon} />

                  <p>Awaiting Hospital response</p>
                </Link>
              </div>

              {/* <div
                className={currentLink === 11 ? style.active : style.menu}
                onClick={() => setCurrentLink(11)}
              >
                <Link className={style.link} to='/declined-orders'>
                  <MdOutlineProductionQuantityLimits size={28} className={style.icon} />

                  <p>Order Decline</p>
                </Link>
              </div> */}

              {/* <div
                className={currentLink === 10 ? style.active : style.menu}
                onClick={() => setCurrentLink(10)}
              >
                <Link className={style.link} to='/ova'>
                  <GiCancel size={28} className={style.icon} />

                  <p> Decline Order</p>
                </Link>
              </div> */}
            </div>
          </div>

          <div className={style.bottomContainer}>
            <div className={style.bottom}>
              <Link to='/settings'>
                <MdSettings size={28} color='#fff' />
              </Link>
              <div className='d-flex flex-column'>
                <div className={style.avatar}>
                  <Image src={avatar} alt='avatar' width={50} height={50} />
                </div>
                <p>{userDetails?.username}</p>
              </div>
              <div
                onClick={() => dispatch(logOut())}
                style={{ cursor: "pointer" }}
              >
                <MdLogout size={28} color='#fff' />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* small size */}
      {!sideNav && (
        <div
          className={style.flexContainer}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className={style.top}>
            <div>
              <div className={style.logo}>
                <img
                  className='lite me-2 ms-2'
                  src={liteSmall}
                  alt='lifebank'
                />
                <GoTriangleRight
                  size={20}
                  color='white'
                  onClick={() => setSideNav(!sideNav)}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <div
                className={currentLink === 1 ? style.active : style.menu}
                onClick={() => setCurrentLink(1)}
              >
                <Link className={style.link} to='/'>
                  <RiDashboardFill size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 2 ? style.active : style.menu}
                onClick={() => setCurrentLink(2)}
              >
                <Link className={style.link} to='/new-orders'>
                  <HiShoppingCart size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 3 ? style.active : style.menu}
                onClick={() => setCurrentLink(3)}
              >
                <Link className={style.link} to='/ongoing-orders'>
                  <BiListCheck size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 4 ? style.active : style.menu}
                onClick={() => setCurrentLink(4)}
              >
                <Link className={style.link} to='/tickets'>
                  <RiCustomerService2Fill size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 5 ? style.active : style.menu}
                onClick={() => setCurrentLink(5)}
              >
                <Link className={style.link} to='/place-order'>
                  <RiListCheck size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 6 ? style.active : style.menu}
                onClick={() => setCurrentLink(6)}
              >
                <Link className={style.link} to='/riders'>
                  <RiEBike2Fill size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 7 ? style.active : style.menu}
                onClick={() => setCurrentLink(7)}
              >
                <Link className={style.link} to='/see-quotation'>
                  <LiaClipboardListSolid size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 8 ? style.active : style.menu}
                onClick={() => setCurrentLink(8)}
              >
                <Link className={style.link} to='/po'>
                  <GrTransaction size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 9 ? style.active : style.menu}
                onClick={() => setCurrentLink(9)}
              >
                <Link className={style.link} to='/bid'>
                  <BiDonateHeart size={28} className={style.icon} />
                </Link>
              </div>

              <div
                className={currentLink === 10 ? style.active : style.menu}
                onClick={() => setCurrentLink(10)}
              >
                <Link className={style.link} to='/hospital-report'>
                  <TbReportSearch  size={28} className={style.icon} />
                </Link>
              </div>

              {/* <div
                className={currentLink === 11 ? style.active : style.menu}
                onClick={() => setCurrentLink(11)}
              >
                <Link className={style.link} to='/declined-orders'>
                  <MdOutlineProductionQuantityLimits  size={28} className={style.icon} />
                </Link>
              </div> */}

              {/* <div
                className={currentLink === 10 ? style.active : style.menu}
                onClick={() => setCurrentLink(10)}
              >
                <Link className={style.link} to='/ova'>
                  <GiCancel size={28} className={style.icon} />
                </Link>
              </div> */}
            </div>
          </div>

          <div className={style.bottomContainer}>
            <div className={style.bottom2}>
              <Link to='/settings'>
                <MdSettings size={28} color='#fff' />
              </Link>
              <div className='d-flex flex-column'>
                <div className={style.avatar}>
                  <Image src={avatar} alt='avatar' width={50} height={50} />
                </div>
                <p>{userDetails?.username}</p>
              </div>
              <div
                onClick={() => dispatch(logOut())}
                style={{ cursor: "pointer" }}
              >
                <MdLogout size={28} color='#fff' />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidenav;
