import React, { useEffect, useState } from "react";
import styled from "./Instruction.module.scss";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { constructOrderDate } from "../../helper/DateFormatter";
import { SyncOutlined } from "@ant-design/icons";
import axiosClient from "../../service/axios";
import { successAlert } from "../NotificationToast";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import Reason from "../modalInstruction/Reason";
import Flag from "../modalInstruction/Flag";

const ProcessDeclinedItems = ({ name, address, tym }) => {
  const { id } = useParams();
  const location = useLocation();
  const { product } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [prices, setPrices] = useState({}); // New state to hold item prices
  const [list, setList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");

  const declineHandler = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `lite/v2/api/admin/ops/declines/list/${id}/StockBank`
      );

      const responseData = response?.data?.data;
      setItems(response?.data?.hospital);
      setData(responseData);
      setLoading(false);
    } catch (error) {
      console.log("error is decline");
    }
  };

  useEffect(() => {
    declineHandler();
  }, []);

  const getList = async () => {
    try {
      const res = await axiosClient.get("lite/v2/api/admin/vendor/list");
      const options = res.data
        .sort((a, b) => a.label - b.label)
        .map((item) => ({
          value: item.name,
        }));
      setList(options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const bgColor = () => {
    if (product === "StockBank") return "#78A55A";
    if (product === "Quip") return "#ffd4c2";
    return "#F0FDE0";
  };

  useEffect(() => {
    setReason(true);
  }, []);

  const color = () => {
    if (product === "StockBank") return "#78A55A";
    if (product === "Quip") return "#c7460f";
    return "#F0FDE0";
  };

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const handlePriceChange = (itemId, price) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [itemId]: price,
    }));
  };

  const processHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        id: id,
        vendor_id: selectedVendor,
        items: items.map((item) => ({
          item_id: item.id,
          price: prices[item.id] || 0,
        })),
      };

      const res = await axiosClient.post(
        `lite/v2/api/admin/declines/ops/pricing/${product}`,
        payload
      );

      if (res.status === 200) {
        setLoading(false);
        setFlag(true);
        // successAlert();
        // delay(2000);
        // navigate("/failed-orders");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-3 mb-5">
        <div className={` d-flex flex-column justify-content-center`}>
          <div className="d-flex justify-content-between mt-3 mb-3">
            <p className={styled.header} style={{ color: color() }}>
              ORDER DETAILS{" | "}
              <span>{`SBO ${id}`}</span>
            </p>
            <p
              // className={styled.name}
              style={{
                // background: bgColor(),
                color: color(),
                textTransform: "uppercase",
                cursor: "pointer",
              }}
            >
              <IoArrowBackCircleSharp size={30} onClick={() => navigate(-1)} />
            </p>
          </div>
          <div style={{ maxHeight: "65vh", overflowY: "scroll" }}>
            <div className={styled.modalContainer2}>
              <p className={styled.title}>item(s)</p>
              <p className={styled.title}>QUANTITY</p>
              <p className={styled.title}>Brand</p>
              <p className={styled.title}>Type</p>
              <p className={styled.title}>Unit Price</p>
            </div>

            <div className={styled.stockContainer}>
              {data?.map((p, index) => (
                <div key={index} className={styled.stock}>
                  <p className={styled.title}>{p.name}</p>
                  <div className={styled.line}></div>
                  <p className={styled.title}>{p.quantity}</p>
                  <div className={styled.line}></div>
                  <p className={styled.title}>{p.brand ? p.brand : "N/A"}</p>
                  <div className={styled.content}></div>
                  <p className={styled.title}>
                    {p.quantitytype ? p.quantitytype : "N/A"}
                  </p>
                  <div className={styled.content}></div>
                  <input
                    className={styled.input}
                    placeholder="Enter Unit Price"
                    value={prices[p.id] || ""}
                    onChange={(e) => handlePriceChange(p.id, e.target.value)}
                  />
                </div>
              ))}
            </div>

            <div className={`${styled.modalContainer1} mt-4`}>
              <p className={styled.title}>total product count</p>
              <div className={styled.line}></div>
              <p className={styled.content}>{data?.length}</p>
            </div>

            <div className={`${styled.modalContainer1} mt-4`}>
              <p className={styled.title}>Select Vendor</p>
              <div className={styled.line}></div>
              <select
                value={selectedVendor}
                onChange={(e) => setSelectedVendor(e.target.value)}
              >
                {list.map((vendor, index) => (
                  <option key={index} value={vendor.value}>
                    {vendor.value}
                  </option>
                ))}
              </select>
            </div>


            {items?.map((item, index) => (
               <div key={index}>
               <div className={styled.modalContainer1}>
                 <p className={styled.title}>hospital name</p>
                 <div className={styled.line}></div>
                 <p className={styled.content}>
                   {item.name ? item.name : "N/A"}
                 </p>
               </div>
               <div className={styled.modalContainer1}>
                 <p className={styled.title}>hospital address</p>
                 <div className={styled.line}></div>
                 <p className={styled.content}>
                   {item.address ? item.address : "N/A"}
                 </p>
               </div>
               {/* <div className={styled.modalContainer1}>
                 <p className={styled.title}>order time</p>
                 <div className={styled.line}></div>
                 <p className={styled.content}>{constructOrderDate(item.tym ? item.tym : "N/A")}</p>
               </div> */}
             </div>
            ))}
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button
              className={` shadow-none ${styled.actionBtn} ${styled.reject}`}
              style={{ background: color() }}
              onClick={processHandler}
            >
              {loading ? <SyncOutlined spin /> : `SEND TO NERVE`}
            </button>
          </div>
        </div>
      </div>

      <Reason reason={reason} setReason={setReason} />
      <Flag flag={flag} setFlag={setFlag} />
    </>
  );
};

export default ProcessDeclinedItems;
