import React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { IoCheckmarkCircle } from "react-icons/io5";
import styles from "./Reason.module.scss";
import { successAlert } from "../NotificationToast";

function Flag({ flag, setFlag }) {
  const navigate = useNavigate();
  const handleModalClose = () => {
    setFlag(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    successAlert();
    setFlag(false);
    setTimeout(() => {
      navigate("/failed-bids");
    }, 4000);
  };

  return (
    <Dialog open={flag} onClose={handleModalClose}>
      <DialogContent style={{ paddingBottom: "0" }}>
        <div className={styles.title}>
          {/* <IoCheckmarkCircle
            style={{ marginRight: "10px" }}
            size={40}
            color="#78A55A"
          /> */}
        </div>

        <div className={styles.formContainer}>
          Have you called the vendor success team?
        </div>
      </DialogContent>

      <DialogActions style={{ paddingRight: "49px", paddingBottom: "49px" }}>
        {/* <div
          style={{
            cursor: "pointer",
            color: "#253858",
            backgroundColor: "#DFE1E6",
            padding: "10px",
            borderRadius: "3px",
            border: "1px solid #DFE1E6",
            fontSize: "14px",
          }}
          onClick={handleModalClose}
        >
          No
        </div> */}
        <div
          style={{
            cursor: "pointer",
            color: "#ffffff",
            backgroundColor: "#b70303",
            padding: "10px",
            borderRadius: "3px",
            border: "1px solid #DFE1E6",
            fontSize: "14px",
          }}
          onClick={handleSubmit}
        >
          Yes
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default Flag;