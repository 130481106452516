import React, { useEffect, useState } from "react";
import ProcessCard from "../../../components/orders/process/ProcessCard";
import style from "./Process.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TbArrowsLeftRight } from "react-icons/tb";
import { SelectField } from "../../../components/Form/SelectField";
import { Formik } from "formik";
import { labXOrders } from "../../../redux/orders/actions/orders.actions";
import { SyncOutlined } from "@ant-design/icons";
import { placeOrder } from "../../../redux/new-order/actions/new-orders.actions";
// import { SuccessModal } from "../../../components/Modal/SuccessModal";
// import MessageInstruction from "../../../components/modalInstruction/MessageInstruction";
import { orderCreateSuccessToast } from "../../../components/NotificationToast";
import dateFormat from "../../../helper/DateFormatter";
import { Col, Row } from "react-bootstrap";

const ProcessNewStockbank = () => {
  // const [successModal, setSuccessModal] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState({ 1: false, 2: false, 3: false });
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  let userToken;
  if (localStorage.getItem("userToken")) {
    userToken = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  useEffect(() => {
    dispatch(labXOrders(id));
  }, [id, dispatch]);

  let precheck;
  if (localStorage.getItem("precheck"))
    precheck = JSON.parse(localStorage.getItem("precheck"));

  let vendor = precheck?.vendor;
  let rider = precheck?.Dispatch;
  let status = precheck?.status;

  let neworder;
  if (localStorage.getItem("newOrder"))
    neworder = JSON.parse(localStorage.getItem("newOrder"));

  console.log("neworder", neworder);

  let formValues;
  if (localStorage.getItem("formValues")) {
    formValues = JSON.parse(localStorage.getItem("formValues"));
  }
  console.log("formValues", formValues.hospitalId);

  const response1 = {
    vendor_id: vendor && vendor[0]?.ref_id,
    agent: userToken?.id,
    order_by: formValues?.hospitalId,
    agentType: neworder?.agentType,
    dispatch_id: rider && rider[0]?.dispatch_id,
    payment: neworder?.payment_type,
    schedule_date: dateFormat.DateYearMonthDay(neworder?.schedule_date),
    schedule_time: dateFormat.TimeOnly(neworder?.schedule_tym),
    products: neworder?.products?.map((p) => ({
      product_id: p?.id,
      brand: p?.brand,
      cartonsize: p?.cartonsize,
      packsize: p?.packsize,
      cost: p?.cost,
      quantity: p?.qty,
    })),
    title:
      neworder?.title === "STOCKBANK"
        ? "Stockbank"
        : neworder?.title === "QUIP"
        ? "Quip"
        : neworder?.title,
  };

  const response2 = {
    vendor_id: vendor && vendor[1]?.ref_id,
    agent: userToken?.id,
    order_by: formValues?.hospitalId,
    agentType: neworder?.agentType,
    dispatch_id: rider && rider[0]?.dispatch_id,
    payment: neworder?.payment_type,
    schedule_date: dateFormat.DateYearMonthDay(neworder?.schedule_date),
    schedule_time: dateFormat.TimeOnly(neworder?.schedule_tym),
    products: neworder?.products?.map((p) => ({
      product_id: p?.id,
      brand: p?.brand,
      cartonsize: p?.cartonsize,
      packsize: p?.packsize,
      cost: p?.cost,
      quantity: p?.qty,
    })),
    title:
      neworder?.title === "STOCKBANK"
        ? "Stockbank"
        : neworder?.title === "QUIP"
        ? "Quip"
        : neworder?.title,
  };

  const handleProcess1 = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, 1: true }));
    await dispatch(placeOrder(response1));
    setLoading((prevLoading) => ({ ...prevLoading, 1: false }));
    orderCreateSuccessToast();
    setTimeout(() => {
      navigateFunc();
    }, 3000);
    // setSuccessModal(true);
  };

  const handleProcess2 = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, 2: true }));
    await dispatch(placeOrder(response2));
    setLoading((prevLoading) => ({ ...prevLoading, 2: false }));
    orderCreateSuccessToast();
    setTimeout(() => {
      navigateFunc();
    }, 3000);
    // setSuccessModal(true);
  };

  const vendorOptions = vendor?.map((v) => ({
    label: v.name,
    value: v.ref_id,
  }));

  const riderOptions = rider?.map((r) => ({
    label: r.dispatch_name,
    value: r.dispatch_id,
  }));

  let checked;
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ _theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "black",
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    value: PropTypes.any,
  };

  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  const colors = () => {
    if (neworder?.title === "BLOOD") return "#B70303";
    if (neworder?.title === "OXYGEN") return "#0779F7";
    if (neworder?.title === "STOCKBANK") return "#066304";
    if (neworder?.title === "QUIP") return "#DA621B";
    return "#DA621B";
  };

  const badgeColor = () => {
    if (neworder?.title === "BLOOD") return "#FDE0E0";
    if (neworder?.title === "OXYGEN") return "#E0EFFD";
    return "#F0FDE0";
  };

  return (
    <div style={{ width: "97%" }}>
      {status === 300 ? (
        <Row className="d-flex align-items-center mb-4">
          <Col md={1}>
            <IoIosArrowDropleftCircle
              size={50}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              color={colors()}
            />
          </Col>
          <Col md={8}>
            <p className={style.warning}>
              No Match Available. Please try again
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="d-flex align-items-center mb-4">
            <Col md={1}>
              <IoIosArrowDropleftCircle
                size={50}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
                color={colors()}
              />
            </Col>
            <Col md={8}>
              <p className={style.header}>BEST MATCH</p>
            </Col>
          </Row>
          <ProcessCard
            id="1"
            name={vendor[0]?.name}
            address={vendor[0]?.address}
            dispatch_name={rider[0]?.dispatch_name}
            dispatchAddress=""
            distance={`${parseInt(vendor[1]?.distance * (65 / 60))} Mins`}
            onClick={handleProcess1}
            color={colors()}
            borderColor={colors()}
            bgColor={colors()}
            loading={loading[1]}
          />
          <ProcessCard
            id={"2"}
            name={vendor[1]?.name}
            address={vendor[1]?.address}
            dispatch_name={rider[1]?.dispatch_name}
            dispatchAddress=""
            distance={`${parseInt(vendor[1]?.distance * (65 / 60))} Mins`}
            onClick={handleProcess2}
            color={colors()}
            borderColor={colors()}
            bgColor={colors()}
            loading={loading[2]}
          />

          <p className={style.header}>CUSTOM MATCH</p>

          <RadioGroup name="use-radio-group" defaultValue="single">
            <MyFormControlLabel
              value="single"
              label="SINGLE DELIVERY"
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: colors(),
                    },
                  }}
                />
              }
            />

            <div className={style.container}>
              <Formik
                initialValues={{
                  vendor_id: "",
                  dispatch_id: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.vendor_id) {
                    errors.vendor_id = "Required";
                  }
                  if (!values.dispatch_id) {
                    errors.dispatch_id = "Required";
                  }
                  return errors;
                }}
                onSubmit={async (values, actions) => {
                  setLoading((prevLoading) => ({ ...prevLoading, 3: true }));
                  const payload = {
                    agent: userToken?.id,
                    order_by: formValues?.hospitalId,
                    agentType: neworder?.agentType,
                    vendor_id: values.vendor_id.value,
                    payment: neworder?.payment_type,
                    dispatch_id: values.dispatch_id.value,
                    schedule_date: dateFormat.DateYearMonthDay(
                      neworder?.schedule_date
                    ),
                    schedule_time: dateFormat.TimeOnly(neworder?.schedule_tym),
                    products: neworder?.products?.map((p) => ({
                      product_id: p?.id,
                      brand: p?.brand,
                      cartonsize: p?.cartonsize,
                      packsize: p?.packsize,
                      cost: p?.cost,
                      quantity: p?.qty,
                    })),
                    title:
                      neworder?.title === "STOCKBANK"
                        ? "Stockbank"
                        : neworder?.title === "QUIP"
                        ? "Quip"
                        : neworder?.title,
                  };
                  await dispatch(placeOrder(payload));
                  setLoading((prevLoading) => ({ ...prevLoading, 3: false }));
                  // setSuccessModal(true);
                  orderCreateSuccessToast();
                  setTimeout(() => {
                    navigateFunc();
                  }, 3000);
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  handleBlur,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={style.innerContainer}>
                      <div className={`${style.formInput1} shadow-none`}>
                        {errors.vendor_id && touched.vendor_id ? (
                          <p className="text-danger mt-1 mx-2">
                            {errors.vendor_id}
                          </p>
                        ) : null}

                        <SelectField
                          className={style.input}
                          name="vendor_id"
                          options={vendorOptions}
                          onChange={handleChange}
                          placeholder="Find Vendor"
                          onBlur={handleBlur}
                          value={values.vendor_id}
                        />
                      </div>
                      <TbArrowsLeftRight size={30} />
                      <div className={`${style.formInput1} shadow-none`}>
                        {errors.dispatch_id && touched.dispatch_id ? (
                          <p className="text-danger mt-1 mx-2">
                            {errors.dispatch_id}
                          </p>
                        ) : null}
                        <SelectField
                          className={style.input}
                          name="dispatch_id"
                          options={riderOptions}
                          onChange={handleChange}
                          placeholder="Find Dispatch Rider"
                          onBlur={handleBlur}
                          value={values.dispatch_id}
                        />
                      </div>

                      <div className={style.content}>
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            type="submit"
                            className={style.button}
                            style={{
                              borderColor: colors(),
                              color: colors(),
                            }}
                          >
                            {loading[3] ? <SyncOutlined spin /> : "process"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </RadioGroup>
        </>
      )}

      {/* create MODAL */}
      {/* <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width="55vw"
        height="60vh"
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button="COMPLETE"
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      /> */}
    </div>
  );
};

export default ProcessNewStockbank;
