import React from "react";
import style from "./Step.module.scss";

const Stepnavigation = (props) => {
  let track;
  if (localStorage.getItem("trackOrder")) {
    track = JSON.parse(localStorage.getItem("trackOrder"));
  }

  // console.log("track:", track);

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div className={style.circleContainer}>
          <div
            className={
              track?.order_state === "Awaiting Pick Up" ||
              track?.order_state === "accepted" ||
              track?.order_state === "enroutepickup" ||
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.circleActive}`
                : `${style.circle}`
            }
          ></div>
          <div
            className={
              track?.order_state === "Awaiting Pick Up" ||
              track?.order_state === "accepted" ||
              track?.order_state === "enroutepickup" ||
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.lineActive}`
                : `${style.line}`
            }
          ></div>
        </div>
        <div>
          <p className={style.label}>Awaiting Acceptance</p>
          <p className={style.description}>{props.dispatch}</p>
        </div>
      </div>

      <div className={style.container}>
        <div className={style.circleContainer}>
          <div
            className={
              track?.order_state === "accepted" ||
              track?.order_state === "enroutepickup" ||
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.circleActive}`
                : `${style.circle}`
            }
          ></div>
          <div
            className={
              track?.order_state === "accepted" ||
              track?.order_state === "enroutepickup" ||
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.lineActive}`
                : `${style.line}`
            }
          ></div>
        </div>
        <div>
          <p className={style.label}>
            Accepted {props.riderName && `(${props.riderName})`}
          </p>
          {/* <p className={style.description}>{props.riderAddress}</p> */}
        </div>
      </div>

      <div className={style.container}>
        <div className={style.circleContainer}>
          <div
            className={
              track?.order_state === "enroutepickup" ||
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.circleActive}`
                : `${style.circle}`
            }
          ></div>
          <div
            className={
              track?.order_state === "enroutepickup" ||
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.lineActive}`
                : `${style.line}`
            }
          ></div>
        </div>
        <div>
          <p className={style.label}>Enroute To Pickup</p>
          <p className={style.description}>{props.arrived}</p>
        </div>
      </div>

      <div className={style.container}>
        <div className={style.circleContainer}>
          <div
            className={
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.circleActive}`
                : `${style.circle}`
            }
          ></div>
          <div
            className={
              track?.order_state === "Picked" ||
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.lineActive}`
                : `${style.line}`
            }
          ></div>
        </div>
        <div>
          <p className={style.label}> Picked Up</p>
          {/* <p className={style.description}>{props.delivered}</p> */}
        </div>
      </div>

      <div className={style.container}>
        <div className={style.circleContainer}>
          <div
            className={
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.circleActive}`
                : `${style.circle}`
            }
          ></div>
          <div
            className={
              track?.order_state === "enroutehospital" ||
              track?.order_state === "Delivered"
                ? `${style.lineActive}`
                : `${style.line}`
            }
          ></div>
        </div>
        <div>
          <p className={style.label}>Enroute To Hospital</p>
          {/* <p className={style.description}>{props.arrived}</p> */}
        </div>
      </div>

      <div className={style.container}>
        <div className={style.circleContainer}>
          <div
            className={
              track?.order_state === "Delivered"
                ? `${style.circleActive}`
                : `${style.circle}`
            }
          ></div>
          <div
            className={
              track?.order_state === "Delivered"
                ? `${style.lineActive}`
                : `${style.line}`
            }
          ></div>
        </div>
        <div>
          <p className={style.label}>Delivered</p>
          {/* <p className={style.description}>{props.arrived}</p> */}
        </div>
      </div>
    </div>
  );
};

export default Stepnavigation;
