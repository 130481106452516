import React, { useEffect, useState } from "react";
import { BellOutlined, SyncOutlined } from "@ant-design/icons";
import { ConfirmationModal } from "../Modal/ConfirmationModal";
import { BsClockHistory } from "react-icons/bs";
import sound from "./alert/BEEPMULT.mp3";
import Styles from "./Navbar.module.scss";
import styled from "./Modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationsList,
  viewNotification,
} from "../../redux/orders/actions/orders.actions";
import dateFormat from "../../helper/DateFormatter";
import { useNavigate } from "react-router-dom";
import { delay } from "../orders/nerve/Nerve";

const Navbar = ({ title, url }) => {
  const [openModal, setopenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { notifications } = useSelector((state) => state.orderReducer);

  // console.log("notifications", notifications);

  let totalNot = notifications?.length;

  function playSound() {
    if (notifications?.length > 0) {
      new Audio(sound).play();
    }
  }

  playSound();

  useEffect(() => {
    setLoading(true);
    fetchOrders();
    const myInterval = setInterval(fetchOrders, 15000);
    setLoading(false);
    return () => {
      // should clear the interval when the component unmounts
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrders = () => {
    dispatch(notificationsList());
  };

  const viewOrder = (id, channel) => {
    dispatch(viewNotification(id, channel));
    setopenModal(false);

    channel === "Dispatch" ? navigate("/ongoing-orders") : channel === "quotation" ? navigate("/see-quotation") : channel === "Bids" ? navigate("/bid") : navigate("/new-orders");
    // navigate("/new-orders");
  };

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  useEffect(() => {
    delay(3000);
    if (!userDetails) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <div className={Styles.navbarCont}>
      <div className={Styles.navbarContInner}>
        <div className={Styles.dashboardName}>
          <p className={`${Styles.dashboardName} d-none d-md-block`}>
            {title || ""}
          </p>
        </div>
        <div
          className={Styles.navContentLeft}
          onClick={() => setopenModal(true)}
        >
          <div className={Styles.navContentLeftIcons}>
            {notifications?.length > 0 ? (
              <BellOutlined spin style={{ color: "white", fontSize: 28 }} />
            ) : (
              <BellOutlined style={{ color: "white", fontSize: 28 }} />
            )}
            <div className={Styles.count}>{totalNot || 0}</div>
          </div>
        </div>
      </div>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        handleClose={() => setopenModal(false)}
        bgcolor="background.paper"
        width="80vw"
        closemodal={() => setopenModal(false)}
        instruction={
          notifications?.length > 0 ? (
            <>
              <div className={styled.title}>NOTIFICATION(S)</div>
              {loading && <SyncOutlined spin />}
              {notifications
                ?.sort((a, b) => (a.id > b.id ? -1 : 1))
                ?.map((n) => (
                  <div className={styled.container} key={n?.id}>
                    <div className={styled.icon}>
                      <BellOutlined style={{ color: "brown", fontSize: 28 }} />
                    </div>
                    <p className={styled.text}>
                      {/* A new{" "} */}
                      <span className={styled.ordertype}>{n?.ordertype}</span>{" "}
                       {/* {n?.channel} */}
                    </p>
                    <div className={styled.time}>
                      <span>
                        <BsClockHistory
                          size={25}
                          color="brown"
                          className="me-3"
                        />
                      </span>
                      <p className={styled.timeText}>
                        {/* {dateFormat.TimeAgo(n?.created)} */}
                        {n?.created}
                      </p>
                    </div>
                    <div
                      className={styled.btn}
                      onClick={() => viewOrder(n?.id, n?.channel)}
                    >
                      View
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <>
              <p className={styled.notification}>
                You don't have new notifications
              </p>
            </>
          )
        }
      />
    </div>
  );
};

export default Navbar;
